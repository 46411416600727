import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import SlickCarousel from "react-slick";
import style from "./customCarouselStyle.js";

const useStyles = makeStyles(style);

export default function CustomCarousel(props) {
  const [, forceRerender] = React.useState(0);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.addEventListener("resize", () => forceRerender((n) => !n));
    return () => {
      window.removeEventListener("resize", () => forceRerender((n) => !n));
    };
  }, []);

  const classes = useStyles();
  return (
    <GridContainer className={classes.root}>
      <GridItem>
        <SlickCarousel
          {...{
            dots: false,
            infinite: true,
            speed: 600,
            slidesToShow:
              window.innerWidth > 1335
                ? Math.min(3, props.children?.length)
                : window.innerWidth > 959
                ? Math.min(2, props.children?.length)
                : 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 4000,
            arrows: true,
          }}
        >
          {props.children}
        </SlickCarousel>
      </GridItem>
    </GridContainer>
  );
}
