import homeBg from "assets/images/home-background.png";
import homeBgMobile from "assets/images/mobile-bg.png";
import { grayColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
  pageRoot,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const homeStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    ...pageRoot,
    padding: "50px 0px",
    backgroundSize: "100%",
    backgroundImage: "url(" + homeBg + ")",
    backgroundPosition: "top center;",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "180%",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "url(" + homeBgMobile + ")",
      backgroundSize: "100%",
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  homeParagraph: {
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 240,
    },
  },
  break: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  title: {
    ...title,
    "&, & + h4": {
      color: whiteColor,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  topSection: {
    height: "49.5vw",
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "85vw",
    },
    [theme.breakpoints.down("xs")]: {
      height: "180vw",
    },
    [theme.breakpoints.up("xl")]: {
      height: 975,
    },
  },
  mainImage: {
    height: "80%",
    objectFit: "contain",
    maxWidth: 650,
    maxHeight: 800,
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  mainImageMobile: {
    width: "90%",
    objectFit: "contain",
    maxHeight: 1000,
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  bookNowContainer: {
    marginTop: "auto",
    marginBottom: 80,
    zIndex: 100,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  quickSteps: {
    position: "absolute",
    bottom: -2,
    right: 35,
    [theme.breakpoints.down("md")]: {
      bottom: 3,
      right: -120,
    },
    "@media (max-width: 1110px)": {
      display: "none",
    },
  },
  skew: {
    transform: "skewX(-41deg)",
    // backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    width: "fit-content",
  },
  stepsContainer: {
    transform: "skewX(41deg)",
    width: "fit-content",
    display: "flex",
    padding: "0px 25px",
    color: grayColor[0],
  },
  singleStepContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 10px",
  },
  stepIcon: {
    marginRight: 5,
  },
  section: {
    padding: "100px 0px",
    margin: "40px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 0px",
    },
  },
  detailedBookingSteps: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  stepsBigImage: {
    borderRadius: 10,
    maxWidth: 500,
    maxHeight: 500,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    boxShadow: "0 0 10px 0 rgb(0 0 0 / 30%);",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  detailedStepContainer: {
    display: "flex",
    margin: "20px 0px",
  },
  stepDescription: {
    fontWeight: 500,
    fontSize: 14,
    marginLeft: 18,
  },
  bigStepIcon: {
    fontSize: 47,
    marginRight: 20,
  },
  header: {
    fontWeight: 700,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  padelRulesImage: {
    borderRadius: 10,
    maxWidth: 600,
    maxHeight: 600,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    boxShadow: "0 0 10px 0 rgb(0 0 0 / 30%);",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginBottom: 20,
    },
  },
  learnContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  learnMore: {
    padding: 0,
    height: 35,
    width: 300,
    // margin: "10px auto",
    marginTop: 30,
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
      fontWeight: "bold",
      height: 35,
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      margin: "auto",
      marginTop: 30,
    },
  },
  padelRulesDescriptionContainerDesktop: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  padelRulesDescriptionContainerMobile: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginTop: 30,
    marginBottom: 40,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // padelRulesDescriptionContainer: {
  //   order: 2,
  //   [theme.breakpoints.down("sm")]: {
  //     order: 3,
  //   },
  // },
  padelRulesImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  description: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
});

export default homeStyle;
