import {
  primaryColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const topCourtsStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    padding: "40px 0px",
    margin: "40px 0px",
  },
  heading: {
    marginBottom: 20,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  primaryColor: {
    color: primaryColor[0],
  },
  locationsContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column",
      flexWrap: "nowrap",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    padding: 0,
    height: 35,
    width: "30%",
    margin: "10px auto",
    marginTop: 30,
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
      fontWeight: "bold",
      height: 35,
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
});

export default topCourtsStyle;
