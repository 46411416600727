import { makeStyles } from "@material-ui/core/styles";
import LocationCard from "components/LocationCard/LocationCard.js";
import Pagination from "components/Pagination/Pagination.js";
import React from "react";
import style from "./locationsStyle.js";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api.js";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(style);

export default function Locations() {
  const classes = useStyles();
  const [sendRequest, loading] = useHttpClient();

  const [locations, setLocations] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [, forceRerender] = React.useState(0);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    (async () => {
      try {
        const response = await sendRequest(api.getAllLocations());
        setLocations(response.data);
      } catch (err) {}
    })();
    window.addEventListener("resize", () => forceRerender((n) => !n));
    return () => {
      window.removeEventListener("resize", () => forceRerender((n) => !n));
    };
  }, []);

  let postsPerPage = 9;

  if (window.innerWidth < 960) {
    postsPerPage = 6;
  }

  let matchingLocations = locations;
  let currentPageContent = locations;

  const indexOfLastLocation = currentPage * postsPerPage;
  const indexOfFirstLocation = indexOfLastLocation - postsPerPage;
  currentPageContent = matchingLocations.slice(
    indexOfFirstLocation,
    indexOfLastLocation
  );
  let currentPageContentElements = [];
  if (currentPageContent.length > 0) {
    currentPageContent.forEach((location, index) => {
      currentPageContentElements.push(
        <LocationCard key={index} location={location} />
      );
    });
  } else {
    currentPageContentElements.push(
      <h5 className={classes.subtitle}>
        No available locations now. Come back later for the top courts in Egypt!
      </h5>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <h2 className={classes.header}>All Locations</h2>
        <h4 className={classes.subtitle}>
          Please select where you want to play then check availability.
        </h4>
        {loading ? (
          <div className={classes.locationsContainer}>
            <Skeleton key={1} className={classes.locationSkeleton} />
            <Skeleton key={2} className={classes.locationSkeleton} />
            <Skeleton key={3} className={classes.locationSkeleton} />
          </div>
        ) : (
          <div>
            <div className={classes.locationsContainer}>
              {currentPageContentElements}
            </div>
            {postsPerPage >= locations.length ? null : (
              <div className={classes.paginationContainer}>
                <Pagination
                  color="primary"
                  postsPerPage={postsPerPage}
                  totalPosts={locations.length}
                  paginate={(page) => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    setCurrentPage(page);
                  }}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
