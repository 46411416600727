export default {
  paginationNext: "NEXT",
  paginationPrev: "PREV",
  noAvailableData: "No data available",
  login: "Log in",
  signUp: "Sign Up",
  signIn: "Sign In",
  alreadyMember: "Already a member?",
  notMember: "Not a member?",
  weekDays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  shortWeekDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  usingEmail: "Using Email",
  moreAboutThis: "More About This",
  firstTitleEmailConfirmation: "A Confirmation Email has been sent to:",
  secondTitleEmailConfirmation:
    "Please check your inbox and confirm your Email",
  resendEmail: "Did not receive email? Resend",
  retryResendEmail: "Resend mail again in",
  firstTitleForgotSucceed: "We've sent you a message at:",
  secondTitleForgotSucceed:
    "Follow the link in that message to reset your password",
};
