import { makeStyles } from "@material-ui/core/styles";
import { whiteColor } from "assets/jss/material-kit-pro-react.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { children, theme, big, className } = props;
  const classes = useStyles();
  const themeType =
    theme === "transparent" || theme === undefined ? false : true;
  const footerClasses = classNames({
    [classes.footerClass]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined,
  });

  const openInstagram = () => {
    const win = window.open("https://www.instagram.com/padelhubegypt/", "_blank");
  };

  const openFacebook = () => {
    const win = window.open(
      "https://www.facebook.com/Padel-Hub-Egypt-593393981353620",
      "_blank"
    );
  };

  const openIncrypt = () => {
    window.open("http://www.incryptsolutions.com", "_blank");
  };

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div>
          <div className={classes.content}>
            <div className={classes.footer}>
              <GridContainer
                justify="center"
                className={classes.gridContainerMargin}
              >
                <GridItem xs={12} sm={12} md={3} style={{ margin: "0px auto" }}>
                  <Link to="/home">
                    <h5>About us</h5>
                  </Link>
                  <p style={{ color: "white", fontWeight: 400 }}>
                    Enjoy a new and safe padel tennis experience only with Padel
                    Hub Egypt.
                    <br />
                    <i style={{ textTransform: "uppercase" }}>#Serve_it</i>{" "}
                    <br />
                    <i style={{ textTransform: "uppercase" }}>#Smash_it</i>{" "}
                    <br />
                    <i style={{ textTransform: "uppercase" }}>#Win_it</i>
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={2} style={{ margin: "0px auto" }}>
                  <h5>Navigation</h5>
                  <ul className={classes.linksVertical}>
                    <li>
                      <Link to="/">HOME</Link>
                    </li>
                    <li>
                      <Link to="/locations">BOOK A COURT</Link>
                    </li>
                    <li>
                      <Link to="/news">NEWS</Link>
                    </li>
                    <li>
                      <Link to="/contactus">Contact Us</Link>
                    </li>
                  </ul>
                </GridItem>
                <GridItem xs={6} sm={6} md={2} style={{ margin: "0px auto" }}>
                  <h5>Follow us on social media</h5>
                  <ul className={classes.linksVertical}>
                    <li>
                      <div
                        onClick={openFacebook}
                        color="white"
                        className={classes.socialContainer}
                      >
                        <i className={"fab fa-facebook " + classes.logoSize} />
                        Facebook
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={openInstagram}
                        color="white"
                        className={classes.socialContainer}
                      >
                        <i className={"fab fa-instagram " + classes.logoSize} />
                        Instagram
                      </div>
                    </li>
                  </ul>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <div>
          {/* <ul className={classes.socialButtons}>
            <li>
              <Button
                justIcon
                simple
                onClick={openFacebook}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-facebook " + classes.logoSize} />
              </Button>
            </li>
            <li>
              <Button
                justIcon
                simple
                onClick={openInstagram}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-instagram " + classes.logoSize} />
              </Button>
            </li>
            <li>
              <Button
                justIcon
                simple
                onClick={openInstagram}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-twitter " + classes.logoSize} />
              </Button>
            </li>
            <li>
              <Button
                justIcon
                simple
                onClick={openInstagram}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-youtube " + classes.logoSize} />
              </Button>
            </li>
            <li>
              <Button
                justIcon
                simple
                onClick={openInstagram}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-linkedin " + classes.logoSize} />
              </Button>
            </li>
          </ul> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className={classNames(classes.pullCenter, classes.copyRight)}>
              Copyright &copy; {(1900 + new Date().getYear()).toString()}{" "}
              <Link to="/" style={{ color: whiteColor }}>
                Padel Hub.
              </Link>{" "}
              All Rights Reserved.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontWeight: 400 }}>Powered by </div>
              <Button
                className={classes.incrypt}
                onClick={openIncrypt}
                color="transparent"
              >
                INCRYPT Solutions
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
};
