import strings from "constants/strings";
import moment from "moment";
import validator from "validator";
import "moment-timezone";

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const getDateString = (date, includeYear = true) => {
  return (
    (includeYear
      ? strings.shortWeekDays[date.getDay()]
      : strings.weekDays[date.getDay()]) +
    ", " +
    date.getDate().toString() +
    "/" +
    // strings.months[date.getMonth()] +
    (date.getMonth() + 1) +
    (includeYear ? "/" + date.getFullYear().toString() : "")
  );
};

export const formatAMPM = (timeString) => {
  var hours = parseInt(timeString.split(":")[0]);
  var minutes = parseInt(timeString.split(":")[1]);
  var ampm = "";
  ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  var strTime = hours.toString() + ":" + minutes.toString() + " " + ampm;
  return strTime;
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  let error = null;

  if (rules.required) {
    isValid = value.trim() !== "";
    error = !isValid ? "Required" : error;
  }

  if (rules.number && isValid) {
    isValid = !isNaN(value);
    error = !isValid ? "Not a valid number" : error;
  }

  if (rules.minLength && isValid) {
    isValid = value.length >= rules.minLength;
    error = !isValid
      ? rules.number
        ? "At least %min% numbers"
        : "At least %min% characters"
      : error;
    if (error) {
      error = error.replace("%min%", rules.minLength);
      error = error.replace("%min%", rules.minLength);
    }
  }

  if (rules.maxLength && isValid) {
    isValid = value.length <= rules.maxLength;
    error = !isValid
      ? rules.number
        ? "At Maximum %max% numbers"
        : "Maximum %max% characters"
      : error;
    if (error) {
      error = error.replace("%max%", rules.maxLength);
      error = error.replace("%max%", rules.maxLength);
    }
  }

  if (rules.phone && isValid) {
    isValid = validator.isMobilePhone(value);
    error = !isValid ? "Not a valid phone number" : error;
  }

  if (rules.email && isValid) {
    var emailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = emailRegEx.test(value.toLowerCase());
    error = !isValid ? "Not a valid email" : error;
  }

  if (rules.password && isValid) {
    var passwordRegEx = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,}$/;
    isValid = passwordRegEx.test(value);
    error = !isValid ? "Password should follow the above rules" : error;
  }

  return error;
};

export const zeroPadding = (number, length = 6) =>
  (Array(length).join("0") + number).slice(-length);

export const msToTime = (s) => {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  return mins + ":" + zeroPadding(secs, 2);
};

export const getWeekDays = (now) => {
  let daysArray = [];
  if (now) {
    daysArray.push({
      name:
        strings.weekDays[now.getDay()] +
        ", " +
        now
          .toLocaleString("en-GB", {
            timeZone: "Africa/Cairo",
          })
          .split(",")[0],
      value: new Date(now.setUTCHours(0, 0, 0, 0)).toISOString(),
    });
    for (let i = 1; i < 7; i++)
      daysArray.push({
        name:
          strings.weekDays[now.addDays(i).getDay()] +
          ", " +
          now
            .addDays(i)
            .toLocaleString("en-GB", {
              timeZone: "Africa/Cairo",
            })
            .split(",")[0],
        value: new Date(now.setUTCHours(0, 0, 0, 0)).addDays(i).toISOString(),
      });
  }

  return daysArray;
};

//returns int from 0 -> 48, ex: hoursFormatToInt(13:30) -> 27
export const hoursFormatToInt = (timeString) => {
  return (
    parseInt(timeString.split(":")[0]) * 2 +
    (parseInt(timeString.split(":")[1]) > 0 ? 1 : 0)
  );
};

//example convert12HrTo24Hr("09:30:00 PM")
//returns [21, 30]
export const convert12HrTo24Hr = (timeString) => {
  const [time, modifier] = timeString.trim().split(" ");

  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  minutes = parseInt(minutes);

  return [hours, minutes];
};

export const addTimeOffset = (gmtTime) => {
  const cairoTz = "Africa/Cairo";
  const gmtOffset = moment().tz(cairoTz).utcOffset() / 60; // Get the GMT offset of Cairo

  const time = moment.utc(gmtTime, "HH:mm:ss").add(gmtOffset, "hours");

  return time.format("HH:mm");
};

export const cairoTz = "Africa/Cairo";

export const cairoTimeOffset = moment().tz(cairoTz).utcOffset() / 60;
