import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomCarousel from "components/CustomCarousel/CustomCarousel.js";
import LocationCard from "components/LocationCard/LocationCard.js";
import React from "react";
import style from "./topCourtsStyle.js";
import { useHistory } from "react-router";
import api from "services/api.js";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(style);

export default function TopCourts() {
  const classes = useStyles();
  const history = useHistory();
  const [sendRequest, loading] = useHttpClient();

  const [featuredLocations, setFeaturedLocations] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getFeaturedLocations());
        setFeaturedLocations(response);
      } catch (err) {}
    })();
  }, []);

  const skeleton = [];

  return featuredLocations?.length > 0 ? (
    <div className={classes.root}>
      <h3 className={classes.heading}>Book now the top courts in Cairo!</h3>
      <CustomCarousel>
        {loading
          ? skeleton
          : featuredLocations.map((location, index) => (
              <LocationCard key={index} mAuto location={location} />
            ))}
      </CustomCarousel>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => {
            history.push({
              pathname: "locations",
            });
          }}
        >
          See all
        </Button>
      </div>
    </div>
  ) : null;
}
