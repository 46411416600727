import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Check from "@material-ui/icons/Check";

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// core components

import styles from "./customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    filled,
    style,
    type,
    defaultValue,
    inputRef,
    inputContainerClasses,
    innerContainerClasses,
    iconStart,
    iconStartClasses,
    helperTextClasses,
    iconEnd,
    iconEndClasses,
    passwordIcon,
    passwordIconClasses,
  } = props;

  const classes = useStyles();
  const labelClasses = classNames({
    // [" " + classes.labelRootError]: error,
    // [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error && !filled,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true && !filled,
    [classes.whiteUnderline]: white,
    [classes.noUnderline]: filled,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [classes.filled]: filled,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  let newInputProps = {
    maxLength: inputProps ? inputProps.maxLength : undefined,
    minLength: inputProps ? inputProps.minLength : undefined,
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  const focusClasses = classNames({
    [classes.focus]: focused,
  });

  const errorIcon = error ? (
    <HighlightOffIcon
      className={classes.feedback + " " + classes.labelRootError}
    />
  ) : success ? (
    <Check className={classes.feedback + " " + classes.labelRootSuccess} />
  ) : null;

  const customInput = (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        style={style}
        id={id}
        {...inputProps}
        inputProps={newInputProps}
        type={
          type
            ? type
            : passwordIcon
            ? showPassword
              ? "text"
              : "password"
            : "text"
        }
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        defaultValue={defaultValue || ""}
        readOnly={false}
        inputRef={inputRef}
      />
      {error ? errorIcon : null}
    </FormControl>
  );

  return (
    <div className={classes.inputContainer + " " + inputContainerClasses}>
      {iconStart || iconEnd ? (
        <div
          className={
            classes.innerContainer +
            " " +
            focusClasses +
            " " +
            innerContainerClasses
          }
        >
          {iconStart && (
            <>
              <div className={classes.iconWrapper + " " + iconStartClasses}>
                {iconStart}
              </div>
              <span className={classes.separator} />
            </>
          )}
          {customInput}
          {passwordIcon && (
            <IconButton
              onClick={() =>
                setShowPassword((prevShowPassword) => !prevShowPassword)
              }
              className={classes.passwordIconButton + " " + passwordIconClasses}
              disabled={false}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
          {iconEnd && (
            <span className={classes.iconWrapper + " " + iconEndClasses}>
              {iconEnd}
            </span>
          )}
        </div>
      ) : (
        customInput
      )}
      <FormHelperText
        className={classes.helperText + " " + helperTextClasses}
        filled
      >
        {error}
      </FormHelperText>
    </div>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  style: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  filled: PropTypes.bool,
};
