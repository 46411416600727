import { makeStyles } from "@material-ui/core/styles";
import Person from "@material-ui/icons/Person";
import Phone from "@material-ui/icons/Phone";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { useForm } from "react-hook-form";
import validator from "validator";
import style from "./personalInfoFormStyle.js";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api";
import { AuthContext } from "shared/context/auth-context";

const useStyles = makeStyles(style);

export default function PersonalInfoForm(props) {
  const classes = useStyles();
  const [sendRequest] = useHttpClient();
  const auth = React.useContext(AuthContext);
  const { user } = props;
  const userData = {
    name: user.name,
    email: user.email,
    phone: user.phone,
  };
  const [loading, setLoading] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState({
    message: ".",
    visible: false,
    error: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      phone: userData?.phone,
      fullname: userData?.fullname,
    },
  });

  const validationRules = {
    fullname: {
      required: "This field is required.",
      pattern: {
        value: /^(\w.+\s).+$/,
        message: "Please insert your full name",
      },
    },
    phone: {
      required: "This field is required.",
      validate: {
        phone: (value) =>
          value !== ""
            ? validator.isMobilePhone(
                `+20${value.substring(value.indexOf("1"))}`
              ) || "Phone number not valid"
            : true,
      },
    },
  };

  const handleUpdateInfo = async () => {
    try {
      setLoading(true);
      let info = {
        name: getValues("fullname"),
        phone: getValues("phone"),
      };
      const res = await sendRequest(api.updateUserInfo(info));
      auth.updateUserData(auth.userData, res);
      setLoading(false);
      setTimeout(() => {
        setResponseMessage({
          visible: true,
          error: false,
          message: "Information updated successfully",
        });
      }, 1000);
      setTimeout(() => {
        setResponseMessage({
          ...responseMessage,
          visible: false,
          error: false,
        });
      }, 5000);
    } catch (err) {}
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(handleUpdateInfo)}>
      <GridContainer className={classes.gridContainerStyle}>
        <GridItem xs={12} sm={12} md={2} className={classes.labelStyle}>
          <h4 className={classes.labelText}>Full Name</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} className={classes.inputStyle}>
          <CustomInput
            iconStart={<Person />}
            style={{ margin: 0, paddingTop: 0 }}
            error={errors.fullname?.message}
            id="fullname"
            key="fullname"
            name="fullname"
            inputProps={{
              onChange: (event) => {
                setValue("fullname", event.target.value);
              },
              placeholder: "Full Name",
              name: "fullname",
            }}
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            defaultValue={userData.name}
            filled
            inputRef={register(validationRules.fullname)}
          />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.gridContainerStyle}>
        <GridItem xs={12} sm={12} md={2} className={classes.labelStyle}>
          <h4 className={classes.labelText}>Phone Number</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} className={classes.inputStyle}>
          <CustomInput
            iconStart={<Phone />}
            error={errors.phone?.message}
            defaultValue={userData.phone}
            inputProps={{
              onChange: (event) => {
                setValue("phone", event.target.value);
              },
              placeholder: "Phone Number",
              name: "phone",
            }}
            id="phone"
            name="phone"
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            filled
            inputRef={register(validationRules.phone)}
            style={{ margin: 0, paddingTop: 0 }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={1} className={classes.gridItemStyle}>
          <div className={classes.saveButton}>
            <Button
              type="submit"
              color="primary"
              className={classes.button}
              round
              loading={loading}
            >
              Save
            </Button>
          </div>
        </GridItem>
      </GridContainer>
      {responseMessage.visible ? (
        <GridContainer className={classes.gridContainerStyle}>
          <GridItem xs={12} sm={12} md={8} className={classes.labelStyle}>
            <div
              className={
                classes.responseMessage +
                " " +
                classes.marginBottomDesktop +
                " " +
                (responseMessage.visible
                  ? classes.responseMessageVisible + " "
                  : "") +
                (responseMessage.error ? classes.dangerText : "")
              }
            >
              {responseMessage.message}
            </div>
          </GridItem>
        </GridContainer>
      ) : (
        <div></div>
      )}
    </form>
  );
}
