import { whiteColor } from "assets/jss/material-kit-pro-react";
import { grayColor } from "assets/jss/material-kit-pro-react";
import { primaryColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const calendarStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  root: {
    // [theme.breakpoints.down("xs")]: {
    // },
  },
  title: {
    margin: 0,
    marginBottom: 20,
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
  },
  colorLabelsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  colorLabelContainer: {
    display: "flex",
    alignItems: "center",
    width: 170,
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      width: 135,
      marginBottom: 8,
    },
  },
  colorLabel: {
    margin: 0,
    fontWeight: 400,
    fontSize: 21,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  colorSample: {
    width: 50,
    height: 25,
    borderRadius: 10,
    marginLeft: "auto",
    border: "1px solid #aaa",
    [theme.breakpoints.down("xs")]: {
      width: 40,
    },
  },
  primaryLightBackground: {
    backgroundColor: primaryColor[1],
  },
  calendarContainer: {
    marginTop: 30,
    marginBottom: 20,
  },
  shortWeekDay: {
    textAlign: "right",
    padding: "0px 10px;",
    fontWeight: 600,
  },
  dayGridContainer: {
    border: "1px solid #666",
    borderRadius: 5,
    overflow: "hidden",
    "& :last-child": {
      border: "none",
    },
  },
  hourContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    borderBottom: "1px solid #999",
  },
  hourLegend: {
    width: 125,
    height: 40,
    textAlign: "center",
    borderRight: "1px solid #aaa",
    paddingTop: 8,
    fontWeight: 400,
  },
  slotContainer: {
    width: "100%",
    height: 40,
  },
  slot: {
    height: "50%",
  },
  dashedBorder: {
    borderBottom: "1px dashed #aaa",
  },
  disabledBackground: {
    backgroundColor: grayColor[6],
  },
  buttonsContainer: {
    marginLeft: -10,
    marginTop: 20,
    display: "flex",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 18px",
    borderRadius: 20,
    margin: "0px 6px",
    "& > span:first-child": {
      color: whiteColor,
      fontWeight: "bold",
    },
  },
});

export default calendarStyle;
