import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import defaultNews from "assets/images/default-image.png";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import style from "./horizontalNewsCardStyle";

const useStyles = makeStyles(style);

export default function HorizontalNewsCardSkeleton(props) {

  const { news, mAuto, openNews } = props;
  const classes = useStyles();

  const rootClasses = classNames({
    [classes.mAuto]: mAuto,
    [classes.root]: true,
  });

  return (
    <div className={rootClasses}>
      <GridContainer>
        <GridItem sm={4}>
          <div className={classes.imageContainer}>
            <Skeleton variant="rect" height="100%">
              <img className={classes.image} src={defaultNews} alt="img1" />
            </Skeleton>
          </div>
        </GridItem>
        <GridItem sm={8}>
          <div className={classes.infoContainer}>
            <Skeleton style={{ marginBottom: 10 }} variant="rect" width="50%" />
            <Skeleton style={{ marginBottom: 10 }} variant="rect" width="100%" height="60%" />
            <div className={classes.cardFooter}>
              <Skeleton style={{ borderRadius: 10 }} variant="rect" width="100%" height="40px" />
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}