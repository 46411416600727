import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table";
import React from "react";
import { formatAMPM } from "utils/index.js";
import BookingsHistorySkeleton from "./BookingsHistorySkeleton";
import style from "./bookingsHistoryStyle.js";
import { useState } from "react";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api";
import enums from "enums";

const useStyles = makeStyles(style);

const getReservationTypeString = (reservationType) => {
  switch (reservationType) {
    case enums.ReservationTypes.NORMAL:
      return "Pending";
    case enums.ReservationTypes.CANCELLED:
      return "Canceled";
    case enums.ReservationTypes.NOSHOW:
      return "No Show";
    case enums.ReservationTypes.PAID:
      return "Completed";
    default:
      return "N/A";
  }
};

export default function BookingsHistory() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [sendRequest] = useHttpClient();
  const [state, setState] = useState({
    bookings: null,
  });

  const stateHandler = (key, value) => {
    setState({ ...state, [key]: value });
  };

  React.useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        const bookings = await sendRequest(api.getBookingsHistory());
        stateHandler("bookings", bookings);
        setLoading(false);
      } catch (err) {
        // setLoading(false);
      }
    })();
  }, []);

  let currentPageContentElements = [];
  if (state.bookings && state.bookings.length > 0) {
    state.bookings.forEach((booking, index) => {
      const bookingDate = new Date(booking.from)
        .toLocaleString("en-GB", { timeZone: "Africa/Cairo" })
        .split(",")[0];
      const bookingTime = new Date(booking.from).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Africa/Cairo",
      });

      const dateString = bookingDate + ", " + bookingTime;
      currentPageContentElements.push([
        <span key={index}>{booking.referenceId}</span>,
        <span key={index}>{dateString}</span>,
        <span key={index}>{`${booking?.duration} minutes`}</span>,
        <span key={index}>{booking.locationName}</span>,
        <span key={index}>{booking.courtName}</span>,
        <span key={index}>
          {booking.price} LE
          {booking.penaltyAdded > 0 ? (
            <div className={classes.penalty}>
              + {parseInt(booking?.penaltyAdded)} LE (penalty)
            </div>
          ) : null}
        </span>,
        <span key={index}>{getReservationTypeString(booking.type)}</span>,
      ]);
    });
  }

  return (
    <div>
      {loading ? (
        <BookingsHistorySkeleton />
      ) : (
        <div className={classes.container + " " + classes.shadowContainer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.tableContainer}>
                <Table
                  tableHead={[
                    "Booking No.",
                    "Date",
                    "Duration",
                    "Where",
                    "Court",
                    "Payment",
                    "Status",
                  ]}
                  tableData={currentPageContentElements}
                  noDataMessage="You don't have any past bookings."
                  pagination
                  striped
                  tableHeaderColor="white"
                  mobileResponsive={false}

                  // round
                />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  );
}
