/*eslint-disable*/
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import strings from "constants/strings";
import React, { useEffect } from "react";
import api from "services/api";
import { AuthContext } from "shared/context/auth-context";
import { useHttpClient } from "shared/hooks/http-hook";
import { msToTime } from "utils";
import validator from "validator";
import emailConfirmationStyle from "./emailConfirmationStyle.js";

const useStyles = makeStyles(emailConfirmationStyle);

let resendTimerInterval;

const timerDuration = 30;
const timerStep = 1;

export default function EmailConfirmation({ ...rest }) {
  const [email, setEmail] = React.useState();
  const [resendRemainingTime, setResendRemainingTime] = React.useState(
    timerDuration * 1000
  );

  const classes = useStyles();

  const [sendRequest] = useHttpClient();

  const auth = React.useContext(AuthContext);

  useEffect(() => {
    if (auth?.userData?.email && validator.isEmail(auth.userData.email)) {
      setEmail(auth.userData.email);
    }
  }, [auth]);

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => {
      if (auth?.userData) {
        try {
          const lastEmailConfirmation = (
            await sendRequest(api.checkResendEmailConfirmation())
          ).lastEmailConfirmation;

          const t1 = new Date(new Date().toUTCString());
          const t2 = new Date(new Date(lastEmailConfirmation).toUTCString());
          let timeRemaining = t1.getTime() - t2.getTime();

          timeRemaining =
            timeRemaining > timerDuration * 1000
              ? 0
              : timerDuration * 1000 - timeRemaining;

          setResendRemainingTime(timeRemaining);
        } catch (err) {}
      }
    })();
  }, [auth]);

  useEffect(() => {
    startResendTimer();

    return () => {
      if (resendTimerInterval) {
        clearInterval(resendTimerInterval);
      }
    };
  }, [resendTimerInterval]);

  const startResendTimer = () => {
    if (resendTimerInterval) {
      clearInterval(resendTimerInterval);
    }

    resendTimerInterval = setInterval(() => {
      if (resendRemainingTime <= 0) {
        clearInterval(resendTimerInterval);
      } else {
        setResendRemainingTime(resendRemainingTime - timerStep * 1000);
      }
    }, timerStep * 1000);
  };

  const resendConfirmationEmailHandler = async () => {
    setResendRemainingTime(timerDuration * 1000);
    startResendTimer();

    try {
      await sendRequest(api.resendEmailConfirmation());
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      <div className={classes.container + " " + classes.boxBackground}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.title}>
                  {strings.firstTitleEmailConfirmation}
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.emailTitle}>{email}</div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.title}>
                  {strings.secondTitleEmailConfirmation}
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={10} sm={10} md={10}>
                {resendRemainingTime > 0 ? (
                  <div className={classes.resendEmailContainer}>
                    {`${strings.retryResendEmail} ${msToTime(
                      resendRemainingTime
                    )} seconds`}
                  </div>
                ) : (
                  <div
                    className={
                      classes.resendEmailContainer + " " + classes.pointer
                    }
                    onClick={resendConfirmationEmailHandler}
                  >
                    {strings.resendEmail}
                  </div>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
