import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import BookNow from "./BookNow/BookNow.js";
import style from "./homeStyle.js";
import LatestNews from "./LatestNews/LatestNews.js";
import SubscribeForm from "./SubscribeForm/SubscribeForm.js";
import TopCourts from "./TopCourts/TopCourts.js";
import { useHistory } from "react-router";

const useStyles = makeStyles(style);

export default function Home() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const classes = useStyles();
  const history = useHistory();
  const padelRules = (
    <>
      <p className={classes.description}>
        Padel is a racquet sport which is played in doubles only. It can be
        played indoors or outdoors. Players are enclosed in an area measuring 10
        by 20 metres with transparent walls and wire mesh fencing in places. The
        two teams are separated by a tennis net and the court is marked out with
        lines and service boxes. Walls around the court mean that the ball can
        bounce in unexpected directions to spice up the game!
      </p>
      <Button
        color="primary"
        className={classes.learnMore}
        onClick={() => {
          history.push({
            pathname: "howtoplay",
          });
        }}
      >
        Learn More
      </Button>
    </>
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.topSection}>
          <Hidden xsDown>
            <img
              className={classes.mainImage}
              alt="main-img"
              src={require("assets/images/main-image.png")}
            />
          </Hidden>
          <Hidden smUp>
            <img
              className={classes.mainImageMobile}
              alt="main-img"
              src={require("assets/images/main-image-mobile.png")}
            />
          </Hidden>
          <div className={classes.bookNowContainer}>
            <BookNow />
          </div>
          <div className={classes.quickSteps}>
            <h4 style={{ textTransform: "uppercase", textAlign: "center" }}>
              Book in 3 quick steps...
            </h4>
            <div className={classes.skew}>
              <div className={classes.stepsContainer}>
                <div className={classes.singleStepContainer}>
                  <DateRangeIcon className={classes.stepIcon} />
                  Check Availability
                </div>
                <div className={classes.singleStepContainer}>
                  <ScheduleIcon className={classes.stepIcon} />
                  Select Duration
                </div>
                <div className={classes.singleStepContainer}>
                  <CheckCircleOutlineIcon className={classes.stepIcon} />
                  Confirmation
                </div>
              </div>
            </div>
          </div>
        </div>
        <LatestNews />
        <TopCourts />
        <GridContainer justify="center" className={classes.section}>
          <GridItem
            md={6}
            sm={12}
            xs={12}
            className={classes.detailedBookingSteps}
          >
            <h2 className={classes.header}>
              Play Padel Tennis in 3 simple steps
            </h2>
            <div className={classes.detailedStepContainer}>
              <DateRangeIcon className={classes.bigStepIcon} />
              <div>
                1. Check Availability <br />
                <div className={classes.stepDescription}>
                  View the online booking sheet to find your preferred time and
                  court.
                </div>
              </div>
            </div>
            <div className={classes.detailedStepContainer}>
              <ScheduleIcon className={classes.bigStepIcon} />
              <div>
                2. Select Duration <br />
                <div className={classes.stepDescription}>
                  Select your preferred available time and book. Register online
                  before the first time you book.
                </div>
              </div>
            </div>
            <div className={classes.detailedStepContainer}>
              <CheckCircleOutlineIcon className={classes.bigStepIcon} />
              <div>
                3. Confirmation <br />
                <div className={classes.stepDescription}>
                  We'll send you confirmation of your booking.
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem md={6} sm={12} xs={12} className={classes.imageContainer}>
            <img
              className={classes.stepsBigImage}
              alt={"3steps"}
              src={require("assets/images/DSC02764.jpg")}
            />
          </GridItem>
        </GridContainer>
      </div>
      <SubscribeForm />
      <div className={classes.container}>
        <GridContainer justify="center" className={classes.section}>
          <GridItem
            md={6}
            sm={12}
            xs={12}
            className={classes.padelRulesHeaderContainer}
          >
            <h2 className={classes.header}>Padel Tennis Rules</h2>
            <div className={classes.padelRulesDescriptionContainerDesktop}>
              {padelRules}
            </div>
          </GridItem>
          <GridItem
            md={6}
            sm={12}
            xs={12}
            className={classes.padelRulesImageContainer}
          >
            <img
              className={classes.padelRulesImage}
              alt={"3steps"}
              src={require("assets/images/DSC02748.jpg")}
            />
            <div className={classes.padelRulesDescriptionContainerMobile}>
              {padelRules}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
