import contactBackground from "assets/images/contactBackground.png";
import { primaryColor, textColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
  whiteColor,
  pageRoot,
} from "assets/jss/material-kit-pro-react.js";

const contactUsStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  container: {
    ...container,
    //backgroundColor: "white",
    zIndex: 1,
  },
  contactRoot: {
    ...pageRoot,
    // marginLeft: -15,
    // marginRight: -15,
    // padding: "60px 50px",
    // paddingBottom: 80,
    // [theme.breakpoints.down("sm")]: {
    //   backgroundImage: "none",
    //   padding: "40px 15px",
    // },
  },
  contactHeader: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    marginBottom: 80,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 40,
      fontSize: "1.5rem",
    },
  },
  contactHeaderText: {
    color: textColor[0],
    fontWeight: "bold",
    objectFit: "contain",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.23",
    letterSpacing: "normal",
    textAlign: "center",
    display: "initial",
    color: "#ebebeb",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  displayNoneMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  contactUnderlinedHeaderContainer: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
  responseMessage: {
    color: primaryColor[0],
    fontSize: 14,
    fontWeight: 300,
    paddingLeft: 5,
    opacity: 0,
    transition: "0.3s all",
  },
  responseMessageVisible: {
    opacity: 1,
  },
  reviewImageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  thankYouText: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  reviewImage: {
    maxWidth: 400,
    maxHeight: 302,
    margin: "80px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 40,
    },
  },
  contactForm: {
    order: 2,
    marginRight: -50,
    paddingRight: 65,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      marginRight: 0,
      paddingRight: 15,
    },
  },
  getInTouch: {
    order: 1,
    backgroundImage: `url(${contactBackground})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left bottom",
  },
  getInTouchText: {
    fontWeight: "700",
    marginBottom: 40,
    objectFit: "contain",
    fontFamily: "Montserrat",
    fontSize: "40px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.23",
    letterSpacing: "normal",
    textAlign: "",
    color: "#3d3d3",
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      marginBottom: 20,
      fontSize: "1.5rem",
      textAlign: "center",
    },
  },
  shadowContainer: {
    width: "90%",
    height: "fit-content",
    marginBottom: 60,
    marginRight: "auto",
    marginLeft: "auto",
    padding: "50px 40px 10px 48px",
    objectFit: "contain",
    borderRadius: "32.5px",
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.5)",
    zIndex: 2,
    position: "relative",
    marginTop: 100,
    backgroundColor: whiteColor,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.5)",
      borderRadius: "32.5px",
      backgroundImage: "none",
    },
  },
  contactUsBox: {
    width: "80%",
    height: "71px",
    //margin:" 0 16.5px 50px",
    position: "absolute",
    top: 0,
    left: "50%",
    padding: "18px 0px",
    display: "flex",
    transform: "translateX(-50%) translateY(-50%)",
    zIndex: 3,
    padding: "18.5px 0px 15px",
    objectFit: "contain",
    borderRadius: "10px",
    boxShadow: "0 0 2.5px 0 rgba(0, 0, 0, 0.4)",
    backgroundColor: "#3d3d3d",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0px",
    },
  },
  infoAreaContainer: {
    marginTop: 60,
    marginBottom: 60,
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
      marginBottom: 60,
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 30px",
    borderRadius: 5,
    marginTop: 30,
    marginRight: 10,
    "& > span:first-child": {
      color: whiteColor,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      width: "100%",
      height: 30,
      margin: "auto",
      marginTop: 30,
    },
  },
  icon: {
    margin: "0px 10px",
    height: 20,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 8px",
    },
  },
  socialIconsDiv: {
    // marginLeft: "40px",
  },
  formControlLabel: {
    marginTop: "10px",
    fontFamily: "Montserrat",
    fontSize: "15px",
  },
});

export default contactUsStyle;
