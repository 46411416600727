import { grayColor, primaryColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const subscribeFormStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  subscribeRoot: {
    backgroundImage:
      "linear-gradient(-41deg, " +
      grayColor[0] +
      " 0%, " +
      grayColor[0] +
      " 60%, " +
      primaryColor[0] +
      " 60%, " +
      primaryColor[0] +
      " 100%)",
    [theme.breakpoints.down("sm")]: {
      backgroundImage:
        "linear-gradient(-41deg, " +
        grayColor[0] +
        " 0%, " +
        grayColor[0] +
        " 10%, " +
        primaryColor[0] +
        " 10%, " +
        primaryColor[0] +
        " 90%, " +
        grayColor[0] +
        " 90%, " +
        grayColor[0] +
        " 100%)",
    },
  },
  skew: {
    transform: "skewX(-41deg)",
    // backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      transform: "unset",
    },
  },
  unskew: {
    transform: "skewX(41deg)",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      transform: "unset",
    },
  },
  primaryBackground: {
    backgroundColor: primaryColor[0],
    padding: "15px 30px",
    paddingLeft: 0,
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "unset",
      width: "fit-content",
      padding: "15px 30px",
    },
  },
  subscribeHeading: {
    color: whiteColor,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  subscribeDescription: {
    color: grayColor[0],
    fontWeight: 500,
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subscribeFormContainer: {
    width: "40%",
    height: "100%",
    paddingLeft: 80,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      paddingLeft: 0,
      paddingBottom: 30,
    },
  },
  subscribeContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px 20px",
    },
  },
  button: {
    position: "relative",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "14px 28px",
    lineHeight: "20px",
    textDecoration: "none",
    textTransform: "capitalize",
    display: "inline-flex",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "0.75rem",
      "& > span:first-child": {
        justifyContent: "center",
      },
    },
  },
  subscribe: {
    borderRadius: 3,
    padding: "0px",
    width: "80%",
    height: 27,
    marginTop: -5,
    // marginBottom: 30,
    display: "flex",
    justifyContent: "center",
    backgroundColor: primaryColor[0],
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
      backgroundColor: grayColor[0],
      "&:hover": {
        backgroundColor: grayColor[0],
      },
      width: "100%",
    },
  },
  input: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: 0,
    },
  },
  subscribeSuccessMessage: {
    color: primaryColor[0],
    fontSize: 14,
    fontWeight: 500,
    // backgroundColor: "rgba(0,0,0,0.3)",
    paddingLeft: 5,
    opacity: 1,
    marginBottom: 10,
    transition: "0.3s all",
    [theme.breakpoints.down("sm")]: {
      color: whiteColor,
    },
  },
  subscribeSuccessMessageVisible: {
    opacity: 1,
  },
});

export default subscribeFormStyle;
