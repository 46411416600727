import { makeStyles } from "@material-ui/core/styles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import successImage from "assets/images/booking-success.png";
import BookingCard from "components/BookingCard/BookingCard";
import Loading from "components/Loading/Loading";
import React from "react";
import { useLocation } from "react-router-dom";
import style from "./bookingSuccessStyle";

const useStyles = makeStyles(style);

function BookingSuccess(props) {
  const classes = useStyles();
  let location = useLocation();
  const [booking, setBooking] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (location.state?.response) {
      // console.log(location.state.response);
      setBooking(location.state.response);
      setLoading(false);
    } else {
    }
  }, []);

  return (
    <div className={classes.root}>
      <Loading loading={loading} style={{ minHeight: 500 }}>
        {window.innerWidth < 768 ? (
          <div className={classes.container}>
            <img className={classes.image} src={successImage} alt="img1" />
            <div className={classes.headingText}>
              <div className={classes.title}>
                your booking has been confirmed
              </div>
              <div className={classes.subtitle}>
                Thank you for booking with Padel Hub.
              </div>
            </div>
            <BookingCard booking={booking} mAuto isConfirmation />
          </div>
        ) : (
          <div className={classes.container}>
            <div className={classes.headingContainer}>
              <CheckCircle className={classes.checkCircle} />
              <div className={classes.titleBox}>
                <p className={classes.title}>your booking has been confirmed</p>
                <p className={classes.subtitle}>
                  Thank you for booking with Padel Hub.
                </p>
              </div>
            </div>
            <div className={classes.cardContainer}>
              <BookingCard booking={booking} mAuto isConfirmation />
            </div>
          </div>
        )}
      </Loading>
    </div>
  );
}

export default BookingSuccess;
