import { textColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
  pageRoot,
} from "assets/jss/material-kit-pro-react.js";

const locationsStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    ...pageRoot,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  header: {
    fontWeight: 800,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 21,
    marginBottom: 40,
    width: 500,
    color: textColor[0],
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "100%",
    },
  },
  locationsContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    margin: "auto",
    paddingLeft: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      justifyContent: "center",
    },
  },
  paginationContainer: {
    margin: "auto",
    marginTop: 30,
    width: "fit-content",
  },
  locationSkeleton: {
    minWidth: 300,
    maxWidth: 350,
    height: 300,
    margin: 20,
    borderRadius: 20,
    width: 350,
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3);",
    transform: "none",
  },
});

export default locationsStyle;
