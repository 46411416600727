import { dangerColor } from "assets/jss/material-kit-pro-react";
import { successColor } from "assets/jss/material-kit-pro-react";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { whiteColor } from "assets/jss/material-kit-pro-react";

const confirmModalStyle = (theme) => ({
  modalFooter: {
    padding: "15px",
    paddingBottom: 5,
    marginTop: "auto",
    textAlign: "center",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  modalRoot: {
    padding: "20px",
    paddingBottom: 0,
    display: "flex",
    backgroundColor: whiteColor,
    boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.5)",
    minWidth: "unset",
  },
  button: {
    padding: "5px 20px",
    borderRadius: 5,
    marginLeft: 20,
    "& > span:first-child": {
      fontSize: 16,
      color: whiteColor,
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  cancel: {
    "& > span:first-child": {
      color: blackColor,
    },
    marginLeft: 0,
  },
  modalHeader: {
    fontWeight: 700,
  },
  success: {
    color: successColor[0],
  },
  error: {
    color: dangerColor[0],
  },
});

export default confirmModalStyle;
