
import {
  container,
  mlAuto,
  mrAuto,
  pageRoot,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const acountSettingsStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    ...pageRoot,
  },
  container: {
    ...container,
    zIndex: 1,
  },

  gridcontainer: {
    
   
  },

  accountSettingsText: {
    fontWeight: "700",
    marginBottom: 40,
    objectFit: "contain",
    fontFamily: "Montserrat",
    fontSize: "40px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.23",
    letterSpacing: "normal",
    textAlign: "",
    color: "#3d3d3",
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      marginBottom: 20,
      fontSize: "1.5rem",
      textAlign: "center"
    },
},
subHeadingText: {
    fontWeight: "550",
    marginBottom: 40,
    objectFit: "contain",
    fontFamily: "Montserrat",
    fontSize: "25px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.23",
    letterSpacing: "normal",
    textAlign: "",
    color: "#3d3d3",
    paddingLeft: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      marginBottom: 20,
      marginLeft: 10,
      fontSize: "1.5rem",
      textAlign: "center"
    },
},
  
  
});

export default acountSettingsStyle;
