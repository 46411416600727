import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table";
import React from "react";
import { formatAMPM } from "utils/index.js";
import style from "./bookingsHistoryStyle.js";

const useStyles = makeStyles(style);

export default function BookingsHistory() {
  const [loading, setLoading] = React.useState(false);
  const bookings = [
    //   {
    //   location: "Shooting Club",
    //   court: "Court A",
    //   date: "2020-12-22T10:00:00.000Z",
    //   duration: "30 minutes",
    //   code: "12345678",
    //   price: "240",
    // },
    // {
    //   location: "Shooting Club",
    //   court: "Court A",
    //   date: "2020-12-22T10:00:00.000Z",
    //   duration: "30 minutes",
    //   code: "12345678",
    //   price: "240",
    // },
  ];

  let currentPageContentElements = [];
  if (bookings.length > 0) {
    bookings.forEach((booking, index) => {
      const bookingDate = new Date(booking.date);
      currentPageContentElements.push([
        <span key={index}>
          {formatAMPM(`${bookingDate.getHours()}:${bookingDate.getMinutes()}`)}{" "}
        </span>,
        <span key={index}>{booking.location}</span>,
        <span key={index}>{booking.court}</span>,
        <span key={index}>{booking.duration}</span>,
        <span key={index}>{booking.price}</span>,
        <span key={index}>{booking.code}</span>,
      ]);
    });
  }
  const classes = useStyles();

  return (
    <div className={classes.container + " " + classes.shadowContainer}>
      <Skeleton style={{ maxWidth: "none" }} height="100px" width="100%">
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.tableContainer}>
              <Table
                tableHead={[
                  "Date",
                  "Where",
                  "Court",
                  "Duration",
                  "Payment",
                  "Code Number",
                ]}
                tableData={currentPageContentElements}
                noDataMessage="You Have No Bookings History."
                pagination
                striped
                tableHeaderColor="white"
                mobileResponsive={false}

                // round
              />
            </div>
          </GridItem>
        </GridContainer>
      </Skeleton>
    </div>
  );
}
