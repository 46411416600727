import { makeStyles } from "@material-ui/core/styles";
import defaultImage from "assets/images/default-image.png";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import style from "./howToPlaySectionStyle.js";

const useStyles = makeStyles(style);

export default function HowToPlaySection(props) {

  const { title, details, imgUrl } = props;
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <GridContainer>
      <GridItem md={11} sm={12} xs={12}>
        <h2 className={classes.heading}>{title}</h2>
      </GridItem>
      <GridItem md={7} sm={12} xs={12} className={classes.rulesDescriptionContainer}>
        <div className={classes.infoContainer}>
          <p className={classes.text}>{details}</p>
        </div>
      </GridItem>
      <GridItem md={5} sm={12} xs={12} className={classes.rulesImageContainer}>
        <img className={classes.image} src={imgUrl ? imgUrl : defaultImage} alt="img1" />
      </GridItem>
    </GridContainer>
  );
}
