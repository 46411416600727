import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomCarousel from "components/CustomCarousel/CustomCarousel.js";
import NewsModal from "components/CustomModals/NewsModal/NewsModal.js";
import NewsCard from "components/NewsCard/NewsCard.js";
import NewsCardSkeleton from "components/NewsCard/NewsCardSkeleton.js";
import React from "react";
import { useHistory } from "react-router";
import api from "services/api.js";
import style from "./latestNewsStyle.js";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(style);

export default function LatestNews() {
  const classes = useStyles();
  const history = useHistory();

  const [news, setNews] = React.useState([]);
  const [newsModalOpen, setNewsModalOpen] = React.useState(false);
  const [selectedNewsIndex, setSelectedNewsIndex] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [sendRequest] = useHttpClient();

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await sendRequest(api.getLatestNews());

        setNews(response);
        setLoading(false);
      } catch (err) {}
    })();
  }, []);

  const showNewsModal = (index) => {
    setSelectedNewsIndex(index);
    setNewsModalOpen(true);
  };

  const skeleton = [
    <NewsCardSkeleton key="new-card-skeleton-1" mAuto vertical />,
    <NewsCardSkeleton key="new-card-skeleton-2" mAuto vertical />,
    <NewsCardSkeleton key="new-card-skeleton-3" mAuto vertical />,
    <NewsCardSkeleton key="new-card-skeleton-4" mAuto vertical />,
  ];

  return news?.length > 0 ? (
    <div className={classes.root}>
      <NewsModal
        open={newsModalOpen}
        onClose={() => setNewsModalOpen(false)}
        news={news[selectedNewsIndex]}
      ></NewsModal>
      <h3 className={classes.heading}>Latest News</h3>
      <CustomCarousel>
        {loading
          ? skeleton
          : news.map((n, index) => (
              <NewsCard
                key={index}
                mAuto
                news={n}
                more={() => {
                  showNewsModal(index);
                }}
              />
            ))}
      </CustomCarousel>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => {
            history.push({
              pathname: "news",
            });
          }}
        >
          See all
        </Button>
      </div>
    </div>
  ) : null;
}
