import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import enums from "enums";
import React from "react";
import { useLocation } from "react-router-dom";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import style from "./emailVerificationStyle";

const useStyles = makeStyles(style);

export default function EmailVerification() {
  const location = useLocation();
  const [verificationStatus, setVerificationStatus] = React.useState(null);

  const [sendRequest] = useHttpClient();

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    (async () => {
      try {
        const token = new URLSearchParams(location.search).get("token");
        const res = await sendRequest(api.verifyEmail(token));
        setVerificationStatus(res.verificationStatus);
      } catch (err) {}
    })();
  }, []);

  const classes = useStyles();

  const verificationMessages = {
    [enums.EmailVerificationStatus.INVALID]: {
      title: "Invalid",
      content: "This verification link is invalid",
    },
    [enums.EmailVerificationStatus.VERIFIED]: {
      title: "Email verified",
      content:
        "Your email has been verified. You can now login and make your first reservation",
    },

    [enums.EmailVerificationStatus.ALREADY_VERIFIED]: {
      title: "Email already verified",
      content:
        "Your email has already been been verified. Login and make your first reservation",
    },
    [enums.EmailVerificationStatus.EXPIRED]: {
      title: "Expired",
      content: "This verification link has expired",
    },
  };

  const verifiedIcon =
    verificationStatus === enums.EmailVerificationStatus.VERIFIED ||
    verificationStatus === enums.EmailVerificationStatus.ALREADY_VERIFIED ? (
      <CheckCircleOutlinedIcon className={classes.checkCircleOutlinedIcon} />
    ) : null;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {verificationStatus !== null ? (
          <h3 className={classes.alignCenter}>
            {verificationMessages[verificationStatus].title}
          </h3>
        ) : null}
        {verifiedIcon}
        {verificationStatus !== null ? (
          <h6 className={classes.alignCenter}>
            {verificationMessages[verificationStatus].content}
          </h6>
        ) : null}
      </div>
    </div>
  );
}
