import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import strings from "constants/strings";
import enums from "enums";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import api from "services/api";
import { AuthContext } from "shared/context/auth-context";
import { useHttpClient } from "shared/hooks/http-hook";
import validator from "validator";
import styles from "./authenticationFormStyle";

const useStyles = makeStyles(styles);

export default function AuthenticationForm(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [
    sendRequest,
    isLoading,
    error,
    paramError,
    clearError,
  ] = useHttpClient();
  const auth = React.useContext(AuthContext);

  const {
    authMode,
    handleAuthModeChange,
    handleCloseAuthModal,
    handleForgotPassword,
  } = props;

  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      password: "",
    },
  });

  React.useEffect(() => {}, [getValues]);

  const validationRules = {
    email: {
      required: "This field is required.",
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email not valid",
      },
      maxLength: {
        value: 50,
        message: "Email cannot exceed 50 characters.",
      },
    },
    name: {
      required: "This field is required.",
      pattern: {
        value: /^(\w.+\s).+$/,
        message: "Please insert your full name",
      },
    },
    phone: {
      required: "This field is required.",
      validate: {
        phone: (value) =>
          validator.isMobilePhone(
            `+20${value.substring(value.indexOf("1"))}`
          ) || "Phone number not valid",
      },
    },
    password: {
      required: "This field is required.",
      minLength: {
        value: 8,
        message: "Password should be at least 8 characters.",
      },
      maxLength: {
        value: 60,
        message: "Password cannot exceed 60 characters.",
      },
    },
  };

  const handleAuthentication = async () => {
    clearError();
    try {
      let authData = {
        email: getValues("email"),
        password: getValues("password"),
      };

      let request;

      if (authMode === enums.AuthMode.SIGNUP) {
        const phone = getValues("phone");
        authData.name = getValues("name");
        authData.phone = `+20${phone.substring(phone.indexOf("1"))}`;
        request = api.signUpWithEmail(authData);
      } else {
        request = api.signInWithEmail(authData);
      }

      const response = await sendRequest(request);

      const newLocation =
        authMode === enums.AuthMode.SIGNUP
          ? "/auth/email/confirm"
          : location.pathname + location.search;
      handleCloseAuthModal();
      auth.login(response);
      history.push(newLocation);
    } catch (err) {}
  };

  const signUpInfo = [
    <CustomInput
      iconStart={<PersonIcon />}
      style={{ margin: 0, paddingTop: 0 }}
      error={errors.name?.message || paramError("name")}
      id="name"
      key="name"
      inputRef={register(validationRules.name)}
      inputProps={{
        onChange: (event) => {
          setValue("name", event.target.value);
        },
        placeholder: "Full Name",
        name: "name",
      }}
      formControlProps={{
        fullWidth: true,
        className: classes.formControl,
      }}
      inputContainerClasses={classes.inputContainer}
      filled
    />,
    <CustomInput
      iconStart={<PhoneIcon />}
      style={{ margin: 0, paddingTop: 0 }}
      error={errors.phone?.message || paramError("message")}
      id="phone"
      key="phone"
      inputRef={register(validationRules.phone)}
      inputProps={{
        onChange: (event) => {
          setValue("phone", event.target.value);
        },
        placeholder: "Phone",
        name: "phone",
      }}
      formControlProps={{
        fullWidth: true,
        className: classes.formControl,
      }}
      inputContainerClasses={classes.inputContainer}
      filled
    />,
  ];

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(handleAuthentication)}
    >
      <GridContainer justify="center" className={classes.rootContainer}>
        <GridItem xs={12} sm={12} md={12} className={classes.rootGridItem}>
          <GridContainer
            justify="center"
            className={classes.headerRootContainer}
          >
            <GridItem xs={12} sm={12} md={6} className={classes.zeroPadding}>
              <p className={classes.authFormTitle}>
                {authMode === enums.AuthMode.SIGNUP
                  ? "CREATE AN ACCOUNT"
                  : "LOG IN"}
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.zeroPadding}>
              <div className={classes.switchAuthModeContainer}>
                <p className={classes.switchAuthModeTitle}>
                  {authMode === enums.AuthMode.SIGNUP
                    ? "Have an account?"
                    : "Don't have an account?"}
                </p>
                <Button
                  className={classes.button}
                  onClick={handleAuthModeChange}
                >
                  {authMode === enums.AuthMode.SIGNUP ? "Log In" : "Sign Up"}
                </Button>
              </div>
            </GridItem>
          </GridContainer>

          {error && !error.param && (
            <FormHelperText className={classes.helperText} filled>
              {error.message}
            </FormHelperText>
          )}

          {authMode === enums.AuthMode.SIGNUP ? signUpInfo : null}

          <CustomInput
            iconStart={<EmailIcon />}
            style={{ margin: 0, paddingTop: 0 }}
            error={errors.email?.message || paramError("email")}
            id="email"
            inputRef={register(validationRules.email)}
            inputProps={{
              onChange: (event) => {
                setValue("email", event.target.value);
              },
              placeholder: "Email",
              name: "email",
            }}
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            inputContainerClasses={classes.inputContainer}
            filled
          />

          <CustomInput
            iconStart={<LockIcon />}
            style={{ margin: 0, paddingTop: 0 }}
            error={errors.password?.message || paramError("password")}
            id="password"
            inputRef={register(validationRules.password)}
            inputProps={{
              onChange: (event) => {
                setValue("password", event.target.value);
              },
              placeholder: "Password",
              name: "password",
            }}
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            inputContainerClasses={classes.inputContainer}
            filled
            passwordIcon
          />

          {authMode === enums.AuthMode.LOGIN ? (
            <div className={classes.forgotPasswordContainer}>
              <div
                className={classes.forgotPassword}
                onClick={handleForgotPassword}
              >
                Forgot password?
              </div>
            </div>
          ) : null}

          <Button
            type="submit"
            className={classes.signUpButton}
            loading={isLoading}
          >
            {authMode === enums.AuthMode.SIGNUP
              ? strings.signUp
              : strings.signIn}
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
}
