import {
  primaryColor,
  dangerColor,
  successColor,
  whiteColor,
  grayColor,
  defaultFont,
  textColor,
} from "assets/jss/material-kit-pro-react.js";

const customInputStyle = (theme) => ({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important",
    },
  },
  noUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottomWidth: "0px !important",
    },
    "&:after": {
      borderBottomColor: "transparent",
    },
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottomColor: grayColor[11] + " !important",
      borderBottomWidth: "1px !important",
    },
    "&:after": {
      borderBottomColor: primaryColor[0],
    },
  },
  underlineError: {
    "&:after": {
      borderBottomColor: dangerColor[0],
    },
  },
  underlineSuccess: {
    "&:after": {
      borderBottomColor: successColor[0],
    },
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[12] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },
  labelRootError: {
    color: dangerColor[0] + " !important",
  },
  labelRootSuccess: {
    color: successColor[0] + " !important",
  },
  feedback: {
    position: "absolute",
    bottom: "4px",
    right: "0",
    zIndex: "2",
    display: "block",
    width: "22px",
    height: "22px",
    textAlign: "center",
    pointerEvents: "none",
  },
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
    position: "relative",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: grayColor[13],
    },

    display: "flex",
    width: "100%",
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottomColor: whiteColor,
    },
    "&:after": {
      borderBottomColor: whiteColor,
    },
  },
  input: {
    color: grayColor[13],
    height: "unset",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1",
    },
    "&::placeholder": {
      color: grayColor[12],
    },
  },
  filled: {
    color: grayColor[13],
    backgroundColor: whiteColor,
    borderRadius: 3,
    padding: "5px 5px",
  },
  whiteInput: {
    "&,&::placeholder": {
      color: whiteColor,
      opacity: "1",
    },
  },
  iconWrapper: {
    float: "left",
    marginTop: "5px",
    marginRight: "10px",
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "white",
    boxShadow: "0 0 2.5px 0 rgba(0, 0, 0, 0.4);",
    borderRadius: 25,
    paddingLeft: 15,
    paddingRight: 5,
    paddingTop: 0,
    margin: "5px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      margin: 0,
    },
    width: "100%",
  },
  separator: {
    backgroundColor: textColor[0],
    width: 1,
    height: 20,
    marginRight: 7,
  },
  helperText: {
    color: dangerColor[0] + " !important",
    fontFamily: "Montserrat",
    marginTop: 5,
    marginLeft: 15,
    fontSize: 15,
    fontWeight: 400,
  },
  passwordIconButton: {
    padding: "0 0 0 3px",
  },
  inputContainer: {
    width: "100%",
  },
  focus: { boxShadow: "0px 0px 5px rgba(244, 115, 32, 0.75) !important" },
  // blur: {},
});

export default customInputStyle;
