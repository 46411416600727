import {
  blackColor,
  grayColor,
  whiteColor,
  textColor,
  infoColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react";

const headerLinksStyle = (theme) => ({
  list: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    marginTop: "0px",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0",
    width: "100%",
  },
  skew: {
    transform: "skewX(-41deg)",
    backgroundColor: grayColor[1],
    width: "fit-content",
    marginLeft: 200,
    height: 50,
    [theme.breakpoints.down("sm")]: {
      transform: "none",
      marginLeft: 0,
      height: "fit-content",
    },
  },
  unskew: {
    transform: "skewX(41deg)",
    padding: "0px 20px",
    paddingRight: "8.8vw", //"11.5vw", //15
    height: "100%",
    boxSizing: "content-box",
    // [theme.breakpoints.down("lg")]: {
    //   paddingRight: "0",
    // },
    [theme.breakpoints.down("md")]: {
      paddingRight: "2vw",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "none",
      minWidth: 0,
      padding: 0,
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("xl")]: {
      paddingRight: 170, //212, //255
    },
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      width: "100%",
      // "&:first-child": {
      //   "&:after": {
      //     width: "calc(100% - 30px)",
      //     content: '""',
      //     display: "block",
      //     height: "1px",
      //     marginLeft: "15px",
      //     backgroundColor: "#aaa",
      //   },
      // },
    },
  },
  listItemText: {
    padding: "0 !important",
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },
  navLinkJustIcon: {
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      marginRight: "0px",
    },
    "& svg": {
      marginRight: "0px",
    },
  },
  navButton: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "5px",
      marginTop: "5px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& $icons": {
      marginRight: "3px",
    },
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  registerNavLink: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  navLinkActive: {
    "&, &:hover, &:focus,&:active ": {
      color: "inherit",
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)",
    },
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "14px",
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "14px",
    opacity: "0.5",
    marginTop: "-4px",
    top: "1px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative",
  },
  socialIcons: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    width: "auto",
    justifyContent: "center",
    color: "inherit",
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      width: "100%",
      margin: "0px auto",
      borderTop: "1px solid #aaa",
      paddingTop: 10,
      paddingBottom: 7,
    },
  },
  userLogo: {
    height: 18,
    marginRight: 2,
    fill: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fill: textColor[0],
    },
  },
  circleUserLogo: {
    height: "24px !important",
    width: "24px !important",
    marginRight: "6px !important",
    marginBottom: "3px !important",
    fill: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fill: textColor[0],
    },
  },
  socialListItem: {
    padding: 0,
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "flex",
      padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    },
  },
  marginRight5: {
    marginRight: "5px",
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto",
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
  },
  mAuto: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  headerLink: {
    fontSize: "14px",
    fontWeight: "600",
    color: textColor[0],
    textDecoration: "none",
    display: "flex",
    padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    "&:hover,&:focus": {
      color: "rgba(" + hexToRgb(blackColor) + ", 0.7)",
    },
    "&:visited": {
      color: textColor[0],
    },
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      padding: 9,
      // justifyContent: "center",
      marginBottom: "5px",
      marginTop: "5px",
      marginLeft: "0 !important",
      "&:hover,&:focus": {
        color: "inherit",
      },
      paddingLeft: 15,
      // "& > span:first-child": {
      //   justifyContent: "center",
      // },
    },
  },
  loginButton: {
    "& > span:first-child": {
      color: whiteColor,
    },
    height: 37,
    padding: 0,
    backgroundColor: "transparent",
    marginRight: 15,
    [theme.breakpoints.down("sm")]: {
      "& > span:first-child": {
        color: textColor[0],
      },
    },
  },
  separator: {
    width: 1,
    backgroundColor: whiteColor,
    height: 15,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: textColor[0],
    },
  },
  signUpButton: {
    "& > span:first-child": {
      color: whiteColor,
    },
    height: 37,
    padding: 0,
    backgroundColor: "transparent",
    marginLeft: 15,
    [theme.breakpoints.down("sm")]: {
      "& > span:first-child": {
        color: textColor[0],
      },
    },
  },
  socialContainer: { marginLeft: -200 },
  authSection: { display: "flex", justifyContent: "center" },
  authModalInfo: {
    backgroundColor: grayColor[27],
  },
  authModalBody: {
    backgroundColor: infoColor[8],
  },
  noPadding: {
    padding: "0",
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  // socialIcons: {
  //   marginLeft: "auto",
  //   display: "flex",
  //   alignItems: "center",
  //   width: "auto",
  //   justifyContent: "center",
  //   color: "inherit",
  //   [theme.breakpoints.down("sm")]: {
  //     "& ul": {
  //       maxHeight: "400px",
  //       overflow: "scroll",
  //     },
  //     width: "100%",
  //     margin: "0px auto",
  //     borderTop: "1px solid #aaa",
  //     paddingTop: 10,
  //     paddingBottom: 7,
  //   },
  // },
  profileButtonHiddenContainer: {
    marginLeft: "auto",
    padding: 0,
  },
  profileButtonContainer: {
    display: "flex",
    width: "auto",
    justifyContent: "center",
    color: "inherit",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      width: "100%",
      margin: "0px auto",
      borderTop: "1px solid #aaa",
      paddingTop: 25,
      paddingBottom: 7,
    },
  },
  profileButton: {
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
      fontWeight: "bold",
    },
    paddingLeft: 20,
    borderRadius: 100,
    padding: 5,
    backgroundColor: "transparent",
  },
  popover: {
    maxWidth: 300,
    padding: "10px 20px",
    overflow: "inherit",
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: -7,
      left: "auto",
      right: 13,
      border: "8px solid white",
      borderColor: "transparent transparent white white",
      transform: "translateX(-50%) rotate(135deg)",
      boxShadow: "-2px 2px 3px rgba(57,73,76,0.1)",
    },
  },
  img: {
    width: "50px !important",
    height: "50px !important",
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "0% 30%",
    marginRight: 10,
  },
  popOverHeading: {
    color: textColor[0],
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
  },
  text: {
    color: textColor[0],
    fontWeight: 300,
    fontSize: 14,
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 10,
    marginBottom: 10,
    borderBottom: "1px solid #aaa",
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      width: "100%",
      paddingTop: 10,
      marginBottom: 0,
      borderBottom: "none",
    },
  },
  userLinkContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      padding: 9,
      marginBottom: "5px",
      marginTop: "5px",
      marginLeft: "0 !important",
      fontSize: "0.75rem",
      "&:hover,&:focus": {
        color: "inherit",
      },
      paddingLeft: 15,
    },
  },
  borderBottomMobile: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 10,
      borderBottom: "1px solid #aaa",
    },
  },
  borderTop: {
    borderTop: "1px solid #aaa",
    paddingTop: 10,
  },
  icon: {
    marginRight: 10,
    marginLeft: 10,
    height: 16,
    width: 16,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 8px",
    },
  },
  logOutIcon: {
    height: 14,
    width: 20,
    marginRight: 10,
    marginLeft: 10,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 8px",
    },
  },
  profileLinksMobileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0 !important",
  },
});

export default headerLinksStyle;
