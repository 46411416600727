import { makeStyles } from "@material-ui/core/styles";
import authModalHeaderImage from "assets/images/auth-modal-header.png";
import Button from "components/CustomButtons/Button";
import CustomModal from "components/CustomModals/CustomModal/CustomModal";
import AuthenticationForm from "components/Forms/AuthenticationForm/AuthenticationForm";
import ForgotPassword from "components/Forms/ForgotPassword/ForgotPassword";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import enums from "enums";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./authModalStyle";

const useStyles = makeStyles(styles);

const SocialButton = (props) => (
  <a
    href={`${
      process.env.REACT_APP_API_HOST
    }/auth/${props.provider.toLowerCase()}`}
    className={props.classes.socialButtonAnchor}
  >
    <Button
      color="white"
      target="_blank"
      className={props.classes.buttonOutlined + " " + props.providerClasses}
      adornment
      round
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <i
        className={
          `fab fa-${props.provider.toLowerCase()}${
            props.provider === "Facebook" ? "-f" : ""
          } ` +
          props.classes[`fa${props.provider}`] +
          " " +
          props.classes.socials
        }
      />
      {`Continue with ${props.provider.toLowerCase()}`}
    </Button>
  </a>
);

export default function AuthModal(props) {
  const classes = useStyles();
  const {
    open,
    onClose,
    children,
    style,
    authMode,
    handleAuthModeChange,
  } = props;

  const [showForgotPasswordForm, setShowForgotPasswordForm] = React.useState(
    false
  );

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      closeButtonClass={classes.closeButton}
      closeButtonIconClass={classes.closeButtonIcon}
      modalBodyClass={classes.modalBodyClass}
    >
      <GridContainer justify="center" className={classes.authModalContainer}>
        <GridItem
          className={classes.noPadding + " " + classes.authModalBody}
          xs={12}
          sm={12}
          md={12}
        >
          <img
            src={authModalHeaderImage}
            className={classes.authModalHeaderImage}
          />
          <h5 className={classes.headerTitle}>Welcome To Padel Hub</h5>
          {showForgotPasswordForm ? (
            <ForgotPassword />
          ) : (
            <div className={classes.authModalBodyContainer}>
              <AuthenticationForm
                authMode={authMode}
                handleAuthModeChange={handleAuthModeChange}
                handleCloseAuthModal={onClose}
                handleForgotPassword={() => setShowForgotPasswordForm(true)}
              />

              <div className={classes.orBorderContainer}>
                <div className={classes.orBorder} />
                <p className={classes.orParagraph}>OR</p>
                <div className={classes.orBorder} />
              </div>

              {/* <SocialButton
                provider="Facebook"
                classes={classes}
                providerClasses={classes.facebookButton}
              /> */}

              <SocialButton
                provider="Google"
                classes={classes}
                providerClasses={classes.googleButton}
              />

              {authMode === enums.AuthMode.SIGNUP && (
                <div className={classes.infoArea}>
                  By signing up you agree to Padel Hub's&nbsp;
                  <br />
                  <Link to="/privacypolicy" className={classes.link}>
                    Terms of Service
                  </Link>
                  &nbsp;and &nbsp;
                  <Link to="/termsofuse" className={classes.link}>
                    Privacy Policy
                  </Link>
                </div>
              )}
            </div>
          )}
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}
