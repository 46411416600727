import React from "react";
import HorizontalNewsCardSkeleton from "./HorizontalNewsCard/HorizontalNewsCardSkeleton";
import VerticalNewsCardSkeleton from "./VerticalNewsCard/VerticalNewsCardSkeleton";

export default function NewsCardSkeleton(props) {

  const { horizontal, long, mAuto } = props;

  return (!horizontal || window.innerWidth < 768) ? (
    <VerticalNewsCardSkeleton long={long} mAuto={mAuto} />
  ) :
    (
      <HorizontalNewsCardSkeleton mAuto={mAuto} />
    );
}