import { makeStyles } from "@material-ui/core/styles";
import NewsModal from "components/CustomModals/NewsModal/NewsModal";
import NewsCard from "components/NewsCard/NewsCard.js";
import NewsCardSkeleton from "components/NewsCard/NewsCardSkeleton.js";
import Pagination from "components/Pagination/Pagination.js";
import React from "react";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api.js";
import style from "./newsStyle.js";

const useStyles = makeStyles(style);

export default function News() {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [news, setNews] = React.useState([]);
  const [newsModalOpen, setNewsModalOpen] = React.useState(false);
  const [selectedNewsIndex, setSelectedNewsIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPosts, setTotalPosts] = React.useState(0);
  const [sendRequest] = useHttpClient();

  const postsPerPage = 5;

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    (async () => {
      try {
        await updateNews(currentPage);
      } catch (err) {}
    })();
  }, []);

  const showNewsModal = (index) => {
    setSelectedNewsIndex(index);
    setNewsModalOpen(true);
  };

  const updateNews = async (page) => {
    setLoading(true);
    const response = await sendRequest(api.getNews(page, postsPerPage));
    setTotalPosts(response.total);
    setNews(response.data);
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <NewsModal
          open={newsModalOpen}
          onClose={() => setNewsModalOpen(false)}
          news={news[selectedNewsIndex]}
        ></NewsModal>
        <h2 className={classes.header}>All News</h2>
        {loading ? (
          <div>
            <NewsCardSkeleton mAuto horizontal />
            <NewsCardSkeleton mAuto horizontal />
            <NewsCardSkeleton mAuto horizontal />
            <NewsCardSkeleton mAuto horizontal />
          </div>
        ) : news.length > 0 ? (
          news.map((n, index) => (
            <NewsCard
              key={index}
              horizontal
              mAuto
              news={n}
              more={() => {
                showNewsModal(index);
              }}
            />
          ))
        ) : (
          <p className={classes.noNewsMessage}>
            No news for now. Come back later for the latest news!
          </p>
        )}
        {postsPerPage >= totalPosts ? null : (
          <div className={classes.paginationContainer}>
            <Pagination
              color="primary"
              postsPerPage={postsPerPage}
              totalPosts={totalPosts}
              paginate={(page) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setCurrentPage(page);
                updateNews(page);
              }}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
