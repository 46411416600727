import {
  container,
  mlAuto,
  mrAuto,
  pageRoot
} from "assets/jss/material-kit-pro-react.js";

const booingSuccessStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    ...pageRoot,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checkCircle: {
    fontSize: 250,
    color: "#EB8A2F",
    position: "relative",
    zIndex: "3",
  },
  titleBox: {
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
    backgroundColor: "#EB9C53",
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    display: "inline-block",
    width: "700px",
    minWidth: "600px",
    height: "120px",
    marginLeft: "-45px",
    zIndex: "2",
    position: "relative",
    padding: "30px 20px",
    textAlign: "center"
  },
  cardContainer: {
    marginTop: "-80px",
    marginLeft: "150px",
  },
  title: {
    color: "black",
    fontSize: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
      fontWeight: "800",
    },
    fontWeight: "700",
    textTransform: "uppercase",
  },
  subtitle: {
    color: "black",
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
    fontWeight: "600",
  },
  image: {
    minHeight: "150px",
    left: "50%",
    marginLeft: "-50vw",
    marginrRight: "-50vw",
    maxWidth: "100vw",
    position: "relative",
    right: "50%",
    width: "100vw",
  },
  headingText: {
    textAlign: "center",
    marginBottom: "30px",
  },
});

export default booingSuccessStyle;
