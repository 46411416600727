import { useState, useCallback, useEffect } from "react";
import api from "services/api";

let logoutTimer;

export const useAuth = () => {
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userData, setUserData] = useState(false);

  const login = useCallback((userData, expirationDate) => {
    const tokenExpirationDate =
      expirationDate ||
      new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);
    setTokenExpirationDate(tokenExpirationDate);
    setUserData(userData);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        ...userData,
        expirationDate: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setUserData(null);
    // localStorage.setItem("userData", null);
    localStorage.removeItem("userData");
  }, []);

  const updateUserData = useCallback((currentUserData, updatedUserData) => {
    setUserData({
      ...currentUserData,
      name: updatedUserData.name,
      phone: updatedUserData.phone,
    });
    localStorage.setItem(
      "userData",
      JSON.stringify({
        ...currentUserData,
        name: updatedUserData.name,
        phone: updatedUserData.phone,
      })
    );
  }, []);

  useEffect(() => {
    if (userData && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [userData, tokenExpirationDate]); // logout

  useEffect(() => {
    const authAutoSignIn = async () => {
      const storedData = JSON.parse(localStorage.getItem("userData"));
      try {
        const user = (await api.getUserInfo()).data;
        login(user);
      } catch (err) {
        setUserData(null);
        localStorage.removeItem("userData");
      }

      // if (
      //   storedData?.emailVerified &&
      //   new Date(storedData.expirationDate) > new Date()
      // ) {
      //   login({
      //     ...storedData,
      //     expirationDate: new Date(storedData.expirationDate),
      //   });
      // } else {
      //   try {
      //     const user = (await api.getUserInfo()).data;
      //     login(user);
      //   } catch (err) {
      //     setUserData(null);
      //     localStorage.removeItem("userData");
      //   }
      // }
    };
    authAutoSignIn();
  }, [login]);

  return { userData, login, logout, updateUserData };
};
