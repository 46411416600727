import {
  blackColor,
  dangerColor,
  hexToRgb,
  primaryColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

const authenticationFormStyle = (theme) => ({
  form: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    marginBottom: 0,
  },
  signUpButton: {
    "& > span:first-child": {
      color: whiteColor,
    },
    padding: 0,
    height: 30,
    width: "100%",
    minHeight: 30,
    backgroundColor: primaryColor[0],
    fontFamily: "sans-serif",
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rootContainer: {
    width: "100%",
  },
  forgotPasswordContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  forgotPassword: {
    color: blackColor,
    cursor: "pointer",
    fontSize: 12,
    fontWeight: 400,
    "&:hover,&:focus": {
      textDecoration: "underline",
      color: blackColor,
    },
  },
  button: {
    backgroundColor: primaryColor[0],
    width: 45.5,
    // height: 20,
    padding: "4px 30px",
    margin: 0,
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.6)",
      color: whiteColor,
    },
    "&:focus": {
      color: whiteColor,
    },
  },
  formControl: {
    margin: 0,
    paddingTop: 0,
  },
  rootGridItem: {
    padding: 0,
    width: "100%",
  },
  headerRootContainer: {
    width: "100%",
    margin: "10px 0 20px 0",
  },
  inputContainer: {
    margin: "10px 0",
  },
  authFormTitle: { margin: 0, color: "black", fontSize: 12 },
  zeroPadding: { padding: 0 },
  switchAuthModeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  switchAuthModeTitle: {
    margin: "0 20px 0 0",
    color: "black",
    fontWeight: "normal",
    fontSize: 12,
  },
  helperText: {
    color: dangerColor[0] + " !important",
    fontFamily: "Montserrat",
    marginTop: 5,
    marginLeft: 15,
    fontSize: 15,
    fontWeight: 400,
  },
});

export default authenticationFormStyle;
