import { pageRoot } from "assets/jss/material-kit-pro-react";

const emailVerificationStyle = (theme) => ({
  checkCircleOutlinedIcon: {
    width: 100,
    height: 100,
    color: "#05B41C",
  },
  root: {
    ...pageRoot,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "90vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "cetner",
    alignItems: "center",
    padding: 25,
  },
  alignCenter: {
    textAlign: "center",
  },
});

export default emailVerificationStyle;
