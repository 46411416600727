import homeBg from "assets/images/home-background.png";
import homeBgMobile from "assets/images/mobile-bg.png";
import {
  grayColor,
  container,
  mlAuto,
  mrAuto,
  pageRoot,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const homeStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    ...pageRoot,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  homeParagraph: {
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 240,
    },
  },
  break: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  title: {
    ...title,
    // marginBottom: "60px",
  },
  textCenter: {
    textAlign: "center",
  },
  topSection: {
    height: "49.5vw",
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "85vw",
    },
    [theme.breakpoints.down("xs")]: {
      height: "180vw",
    },
    [theme.breakpoints.up("xl")]: {
      height: 975,
    },
  },
  mainImage: {
    height: "80%",
    objectFit: "contain",
    maxWidth: 650,
    maxHeight: 800,
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  mainImageMobile: {
    width: "90%",
    objectFit: "contain",
    maxHeight: 1000,
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  bookNowContainer: {
    marginTop: "auto",
    marginBottom: 80,
    zIndex: 100,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  quickSteps: {
    position: "absolute",
    bottom: -2,
    right: 35,
    [theme.breakpoints.down("md")]: {
      bottom: 3,
      right: -120,
    },
    "@media (max-width: 1110px)": {
      display: "none",
    },
  },
  noNewsMessage: {
    padding: 10,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      textAlign: "center",
    },
  },
  skew: {
    transform: "skewX(-41deg)",
    // backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    width: "fit-content",
  },
  stepsContainer: {
    transform: "skewX(41deg)",
    width: "fit-content",
    display: "flex",
    padding: "0px 25px",
    color: grayColor[0],
  },
  singleStepContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 10px",
  },
  stepIcon: {
    marginRight: 5,
  },
  paginationContainer: {
    margin: "auto",
    marginTop: 30,
    width: "fit-content",
  },
  header: {
    fontWeight: 700,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
});

export default homeStyle;
