import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import defaultNews from "assets/images/default-image.png";
import classNames from "classnames";
import React from "react";
import style from "./verticalNewsCardStyle.js";

const useStyles = makeStyles(style);

export default function VerticalNewsCardSkeleton(props) {

  const { long, mAuto } = props;
  const classes = useStyles();

  const rootClasses = classNames({
    [classes.mAuto]: mAuto,
    [classes.root]: true,
  });

  const imageContainerClasses = classNames({
    [classes.longCard]: long,
    [classes.imageContainer]: true,
  });

  const textClasses = classNames({
    [classes.text]: true,
    [classes.longText]: long,
  });

  return (
    <div className={rootClasses}>
      <div className={classes.container}>
        <Skeleton style={{ margin: "auto" }} variant="rect">
          <div className={imageContainerClasses}>
            <img className={classes.image} src={defaultNews} alt="img1" />
          </div>
        </Skeleton>
        <div className={classes.infoContainer}>
          <Skeleton style={{ marginBottom: 10 }} variant="rect" width="50%" />
          <Skeleton style={{ marginBottom: 10 }} variant="rect" width="80%" />
          <Skeleton style={{ marginBottom: 10 }} variant="rect" width="100%" >
            <div className={textClasses}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Curabitur dignissim urna sit amet vestibulum ultricies.
              Sed dignissim, quam eu gravida rutrum, velit leo sollicitudin magna,
              non lobortis orci risus sit amet lectus. Pellentesque et diam purus.
            </div>
          </Skeleton>
          <div className={classes.cardFooter}>
            <Skeleton style={{ marginBottom: 10, borderRadius: 10 }} variant="rect" width="100%" height="40px" />
          </div>
        </div>
      </div>
    </div>
  );
}