import { primaryColor, textColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const howToPlaySectionStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  heading: {
    marginBottom: 20,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  primaryColor: {
    color: primaryColor[0],
  },
  image: {
    borderRadius: 10,
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    marginTop: -70,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  infoContainer: {
    paddingRight: "30px",
  },
  text: {
    fontSize: 14,
    fontWeight: "500",
  },
  rulesDescriptionContainer: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 3,
    },
  },
  rulesImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    order: 3,
    [theme.breakpoints.down("sm")]: {
      order: 2,
      marginBottom: "20px",
    },
  },
});

export default howToPlaySectionStyle;
