import mainBg from "assets/images/main-bg.png";
import homeBgMobile from "assets/images/mobile-bg.png";
import {
  container,
  mlAuto,
  mrAuto,
  pageRoot,
} from "assets/jss/material-kit-pro-react.js";

const howToPlayStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    ...pageRoot,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  section: {
    padding: "0px 20px",
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 50,
    },
  },
});

export default howToPlayStyle;
