import { makeStyles } from "@material-ui/core/styles";
import Phone from "@material-ui/icons/Phone";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import React from "react";
import facebook from "assets/images/facebook.png";
import instagram from "assets/images/instagram.png";
import api from "services/api.js";
import { checkValidity } from "utils/index.js";
import style from "./contactUsStyle.js";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(style);

export default function ContactUs() {
  const classes = useStyles();
  const [sendRequest] = useHttpClient();
  const [values, setValues] = React.useState({
    firstName: {
      value: "",
      error: null,
      validation: {
        required: true,
        minLength: 1,
        maxLength: 100,
      },
    },
    lastName: {
      value: "",
      error: null,
      validation: {
        required: true,
        minLength: 1,
        maxLength: 100,
      },
    },
    email: {
      value: "",
      error: null,
      validation: {
        required: true,
        minLength: 1,
        maxLength: 100,
        email: true,
      },
    },
    phone: {
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      error: null,
    },
    message: {
      value: "",
      error: null,
      validation: {
        required: true,
        minLength: 1,
        maxLength: 500,
      },
    },
  });

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [loading, setLoading] = React.useState(false);
  const [responseMessageVisibleClass, setResponseMessageVisibleClass] =
    React.useState("");
  const [responseMessage, setresponseMessage] = React.useState("");

  const handleChange = (prop) => (event) => {
    const updatedElement = { ...values[prop] };
    updatedElement.value = event.target.value;
    setValues({ ...values, [prop]: updatedElement });
  };

  const handleSubmit = async () => {
    let isFormValid = true;
    let updatedValues = { ...values };
    Object.keys(updatedValues).forEach((key) => {
      const updatedElement = { ...updatedValues[key] };
      updatedElement.error = checkValidity(
        updatedElement.value,
        updatedElement.validation
      );
      updatedValues = { ...updatedValues, [key]: updatedElement };
      isFormValid = isFormValid && (updatedElement.error ? false : true);
    });
    setValues(updatedValues);
    if (isFormValid) {
      try {
        setLoading(true);
        const subscribeResponse = await sendRequest(
          api.contactUs({
            firstName: values.firstName.value,
            lastName: values.lastName.value,
            email: values.email.value,
            phone: values.phone.value,
            message: values.message.value,
          })
        );
        setLoading(false);
        setresponseMessage(
          "Thank you for contacting us, we will get back to you shorlty."
        );
        setTimeout(() => {
          setResponseMessageVisibleClass(classes.responseMessageVisible);
          setValues(updatedValues);
        }, 1000);
        setTimeout(() => {
          setResponseMessageVisibleClass("");
        }, 5000);
      } catch (err) {
        // console.log(err);
        setresponseMessage(err.response.data.message);
        setLoading(false);
        setTimeout(() => {
          setResponseMessageVisibleClass(classes.responseMessageVisible);
        }, 1000);
        setTimeout(() => {
          setResponseMessageVisibleClass("");
        }, 5000);
      }
    }
  };

  const openInstagram = () => {
    const win = window.open(
      "https://www.instagram.com/padelhubegypt/",
      "_blank"
    );
  };

  const openFacebook = () => {
    const win = window.open(
      "https://www.facebook.com/Padel-Hub-Egypt-593393981353620",
      "_blank"
    );
  };
  return (
    <div className={classes.contactRoot}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.getInTouch + " " + classes.mAuto}
          >
            <div className={classes.infoAreaContainer}>
              <div>
                <h3 className={classes.getInTouchText}>Get In Touch</h3>
              </div>
              <InfoArea
                className={classes.info}
                title="Give us a ring"
                description={
                  <p>
                    Abdelrahman Fawzy <br />{" "}
                    <a
                      style={{
                        color: "#3D3D3D",
                        textDecoration: "underline",
                      }}
                      href="tel:+201125130178"
                    >
                      +201125130178
                    </a>
                    <br />
                    <a
                      style={{
                        color: "#3D3D3D",
                        textDecoration: "underline",
                      }}
                      href="mailto:abc@example.com"
                    >
                      info@padelhubeg.com
                    </a>
                  </p>
                }
                icon={Phone}
                iconColor="primary"
              />
              <InfoArea
                className={classes.info}
                title="Follow us on social media"
                description={
                  <div className={classes.socialIconsDiv}>
                    <img
                      src={facebook}
                      alt="facebook"
                      className={classes.icon}
                      onClick={openFacebook}
                    />
                    <img
                      src={instagram}
                      alt="instagram"
                      className={classes.icon}
                      onClick={openInstagram}
                    />
                  </div>
                }
                icon={ThumbUpIcon}
                iconColor="primary"
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.contactForm}>
            <div className={classes.shadowContainer}>
              <div className={classes.contactUsBox}>
                <h3 className={classes.contactHeaderText}>Contact Us</h3>
              </div>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      error={values.firstName.error ? true : false}
                      value={values.firstName.value}
                      inputProps={{
                        onChange: handleChange("firstName"),
                      }}
                      labelText="First Name"
                      id="firstname1"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      error={values.lastName.error ? true : false}
                      value={values.lastName.value}
                      inputProps={{
                        onChange: handleChange("lastName"),
                      }}
                      labelText="Last Name"
                      id="float"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CustomInput
                  error={values.email.error ? true : false}
                  value={values.email.value}
                  inputProps={{
                    onChange: handleChange("email"),
                  }}
                  labelText="Email address"
                  id="float"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
                <CustomInput
                  error={values.phone.error ? true : false}
                  value={values.phone.value}
                  inputProps={{
                    onChange: handleChange("phone"),
                  }}
                  labelText="Phone"
                  id="float"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
                <CustomInput
                  error={values.message.error ? true : false}
                  value={values.message.value}
                  inputProps={{
                    onChange: handleChange("message"),
                    multiline: true,
                    rows: 6,
                  }}
                  labelText="Your message"
                  id="float"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
                <div className={classes.textCenter}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}></GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <Button
                        onClick={handleSubmit}
                        color="primary"
                        className={classes.button}
                        round
                        loading={loading}
                      >
                        Send Message
                      </Button>
                    </GridItem>
                  </GridContainer>
                </div>
                <div
                  className={
                    classes.responseMessage + " " + responseMessageVisibleClass
                  }
                >
                  {responseMessage}
                </div>
              </form>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
