import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModals/CustomModal/CustomModal.js";
import React from "react";
import PropTypes from "prop-types";
import style from "./confirmModalStyle.js";

const useStyles = makeStyles(style);

export default function ConfrimModal(props) {
  const {
    isOpen,
    onClose,
    onConfirm,
    loading,
    headerMessage,
    confirmMessage,
    errorMessage,
    successMessage,
    activeScreen,
  } = props;

  const classes = useStyles();

  let modalConent = <div />;

  if (activeScreen === "question") {
    modalConent = (
      <div>
        <h4 className={classes.modalHeader}>{headerMessage}</h4>
        <div className={classes.modalBody}>
          <p>{confirmMessage}</p>
        </div>
        <div className={classes.modalFooter}>
          <Button
            color="transparent"
            className={classes.button + " " + classes.cancel}
            onClick={onClose}
            round
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.button}
            onClick={onConfirm}
            loading={loading}
            round
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  } else if (activeScreen === "success") {
    //success
    modalConent = (
      <div>
        <h4 className={classes.modalHeader + " " + classes.success}>Success</h4>
        <div className={classes.modalBody}>
          <p>{successMessage}</p>
        </div>
        <div className={classes.modalFooter}>
          <Button
            color="transparent"
            className={classes.button + " " + classes.cancel}
            onClick={onClose}
            round
          >
            OK
          </Button>
        </div>
      </div>
    );
  } else if (activeScreen === "error") {
    //error
    modalConent = (
      <div>
        <h4 className={classes.modalHeader + " " + classes.error}>Error</h4>
        <div className={classes.modalBody}>
          <p>{errorMessage}</p>
        </div>
        <div className={classes.modalFooter}>
          <Button
            color="transparent"
            className={classes.button + " " + classes.cancel}
            onClick={onClose}
            round
          >
            OK
          </Button>
        </div>
      </div>
    );
  }

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalRootClass={classes.modalRoot}
    >
      {modalConent}
    </CustomModal>
  );
}

ConfrimModal.defaultProp = {
  isOpen: false,
  loading: false,
  headerMessage: "Confirmation",
  confirmMessage: "Are you sure?",
  errorMessage: "Something went wrong",
  successMessage: "Action performed successfully",
  activeScreen: "question",
};

ConfrimModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  loading: PropTypes.bool,
  headerMessage: PropTypes.string,
  confirmMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  activeScreen: PropTypes.string,
};
