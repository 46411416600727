import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import style from "./howToPlayStyle.js";
import HowToPlaySection from "./HowToPlaySection/HowToPlaySection";

const useStyles = makeStyles(style);

export default function HowToPlay() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.section}>
          <h1 className={classes.heading}>How To Play Padel?</h1>
          <HowToPlaySection
            title="Padel Rules"
            imgUrl={require("assets/images/DSC02603.jpg")}
            details="Padel is a racquet sport which is played in doubles only.
          It can be played indoors or outdoors.
          Players are enclosed in an area measuring 10 by 20 metres with transparent walls and wire mesh fencing in places.
          The two teams are separated by a tennis net and the court is marked out with lines and service boxes.
          Walls around the court mean that the ball can bounce in unexpected directions to spice up the game!"
          />
          <HowToPlaySection
            title="Points"
            imgUrl={require("assets/images/DSC02751.jpg")}
            details="Padel uses the same scoring system as tennis.
          Six games are needed to win a set and the team which wins two sets wins the game.
          A game is scored exactly the same as in tennis, i.e. 15/0, 30/0, 40/0, advantage, deuce, etc.
          If a set reaches a 6/6 draw, there is a tie-breaker as in tennis."
          />
          <HowToPlaySection
            title="Serve"
            imgUrl={require("assets/images/DSC0335.jpg")}
            details="A game is started by serving diagonally as in tennis, with two serve attempts allowed (first and second service).
          The server must be behind the service line.
          The returner may stand wherever they like on the court, diagonally opposite the server.
          The server first bounces the ball then hits it below waist level.
          The ball must bounce in the service box opposite before being hit by the returner.
          If the ball does not bounce in the box, it is a fault.
          If it bounces in the box and hits the wire mesh fencing before crossing the service line (on the returner's side), it is also a fault.
          If the ball hits the net and bounces in the box it is a let. If it touches the wire mesh fencing before the second bounce, it is a fault."
          />
          <HowToPlaySection
            title="Play"
            imgUrl={require("assets/images/DSC02764.jpg")}
            details="Once the ball is in play, all balls which cross the net must first bounce on the ground on the opponent's side before hitting a wall.
          Players may hit a ball as a volley.
          Players may hit the ball after it has bounced on a wall to send it back to the opponent's side.
          Just as in tennis, the ball may only bounce once in your side, and it may only be hit once.
          You may smash the ball hard so that it goes out after it bounces!
          But the opposing team may run to try and hit it back into play.
          This type of point is particularly exciting!"
          />
        </div>
      </div>
    </div>
  );
}
