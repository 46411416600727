import { makeStyles} from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import style from "./accountSettingStyle.js";
import PersonalInfoForm from "./PersonalInfoFrom/PersonalInfoForm.js";
import PasswordForm from "./PasswordForm/PasswordForm.js";
import { AuthContext } from "shared/context/auth-context";

const useStyles = makeStyles(style);

export default function AccountSettings() {
    
    const auth = React.useContext(AuthContext);
    
    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });  
    }, []);

   
    const classes = useStyles();



    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div>
                    <h3 className={classes.accountSettingsText}>
                        Account Settings
                    </h3>
                    <GridContainer className={classes.gridcontainer}>
                        <h3 className={classes.subHeadingText}>
                            Personal Information
                        </h3>
                        <GridItem xs={12} sm={12} md={12}>
                            <PersonalInfoForm user={auth.userData ? auth.userData : null} />
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.gridcontainer}>
                        <h3 className={classes.subHeadingText}>
                            Password
                        </h3>
                        <GridItem xs={12} sm={12} md={12}>
                            <PasswordForm />
                        </GridItem>
                    </GridContainer>

                </div>
            </div>
        </div>
    );
}
