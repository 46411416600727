import { textColor, whiteColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const verticalNewsCardStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  root: {
    borderRadius: "10px",
    width: 350,
    minWidth: 310,
    maxWidth: 350,
    margin: "20px",
    backgroundColor: whiteColor,
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    marginBottom: "40px",
  },
  container: {
    minHeight: 300,
  },
  imageContainer: {
    height: 220,
    width: "100%",
  },
  longCard: {
    height: 300,
  },
  image: {
    borderRadius: "10px 10px 0px 0px",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center center",
  },
  infoContainer: {
    padding: "10px 10px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingBottom: 10,
  },
  title: {
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "0.9rem",
    marginBottom: "10px",
  },
  text: {
    color: textColor[0],
    fontSize: "0.8rem",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "500",
    minHeight: "72px",
  },
  longText: {
    WebkitLineClamp: 6,
  },
  cardFooter: {
    padding: "15px",
    marginTop: "auto",
    textAlign: "center",
  },
  moreButton: {
    alignSelf: "flex-end",
    textAlign: "center",
    display: "block",
    width: "100%",
    padding: "10px 20px",
    "& > span:first-child": {
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  },
  date: {
    color: "gray",
    fontWeight: "500",
    fontSize: "0.8rem",
  },
});

export default verticalNewsCardStyle;
