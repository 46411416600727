import { whiteColor } from "assets/jss/material-kit-pro-react";
import { textColor, warningColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const locationCardStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  root: {
    minWidth: 300,
    maxWidth: 350,
    margin: "20px",
    borderRadius: "20px",
    overflow: "hidden",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 10px",
      width: "90%",
    },
  },
  container: {
    // minHeight: 300,
  },
  imageContainer: {
    height: 200,
    width: "100%",
    borderBottom: "1px solid " + textColor[0],
    position: "relative",
    backgroundColor: "white",
  },
  nameContainer: {
    height: 40,
    backgroundImage:
      "linear-gradient(to bottom, transparent , rgba(0, 0, 0, 0.6))",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    bottom: 0,
    width: "100%",
    padding: "0px 10px",
  },
  longCard: {
    height: 300,
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center center",
  },
  infoContainer: {
    padding: "15px 10px",
    display: "flex",
    flexDirection: "column",
    height: "45%",
    paddingBottom: 10,
    backgroundColor: whiteColor,
  },
  locationTitle: {
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "0.9rem",
    color: whiteColor,
    margin: 0,
  },
  ratingContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 26,
  },
  ratingStar: {
    fill: warningColor[0],
    fontSize: "1rem",
  },
  rating: {
    color: warningColor[0],
    fontSize: "0.8rem",
    marginTop: 2,
    marginRight: 10,
  },
  ratingCount: {
    color: textColor[1],
    fontSize: "0.8rem",
    marginLeft: 10,
  },
  text: {
    color: textColor[0],
    fontSize: "0.8rem",
  },
  cardFooter: {
    justifySelf: "flex-end",
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  addressContainer: {
    display: "flex",
    alignItems: "center",
  },
  address: {
    color: textColor[0],
    fontSize: "0.8rem",
    fontWeight: "400",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },
  icon: {
    color: textColor[0],
    height: 16,
    width: 16,
    marginRight: 5,
  },
  bookButton: {
    padding: 0,
    width: "80%",
    height: 35,
    // justifySelf: "flex-end",
    margin: "10px auto",
    marginTop: 15,
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  },
});

export default locationCardStyle;
