import { makeStyles } from "@material-ui/core/styles";
import defaultNews from "assets/images/default-image.png";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModals/CustomModal/CustomModal.js";
import React from "react";
import { getDateString } from "utils";
import style from "./newsModalStyle.js";

const useStyles = makeStyles(style);

export default function NewsModal(props) {
  const classes = useStyles();

  const { open, onClose, news } = props;

  return (
    <CustomModal open={open} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={news?.imgUrl ? news?.imgUrl : defaultNews} alt="img1" />
        </div>
        <div>
          <span className={classes.title}>{news?.title}</span>
          <span className={classes.date}>- {news?.createdAt ? getDateString(new Date(news?.createdAt)) : ""}</span>
        </div>
        <p className={classes.text}>{news?.description}</p>
        <div className={classes.modalFooter}>
          <Button
            color="primary"
            className={classes.button}
            onClick={() => (onClose())}
            round
          >{"close"}</Button>
        </div>
      </div>
    </CustomModal>
  );
}
