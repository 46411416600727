import { primaryColor } from "assets/jss/material-kit-pro-react";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
  pageRoot,
  textColor,
  dangerColor,
} from "assets/jss/material-kit-pro-react.js";
import locationBg from "assets/images/marker.png";

const locationDetailsStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    ...pageRoot,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  topBanner: {
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content !important",
    },
  },
  locationHeading: {
    color: whiteColor,
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
    },
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  subtitle: {
    color: whiteColor,
    fontSize: 15,
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  subtitleIcon: {
    color: whiteColor,
    height: 16,
    width: 16,
    marginRight: 5,
    marginTop: 4,
  },
  textColor: {
    color: textColor[0],
  },
  whiteContainer: {
    margin: "20px 0px",
    minWidth: 300,
    minHeight: 300,
    padding: "20px 20px",
    borderRadius: "20px",
    boxShadow: " 0 0 5px 0 rgba(0, 0, 0, 0.5)",
    backgroundColor: whiteColor,
  },
  title: {
    fontWeight: 700,
    margin: "10px 0px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  containerTitle: {
    fontWeight: 600,
    margin: 0,
    marginBottom: 20,
  },
  break: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  descriptionContainerLg: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  descriptionContainerMd: {
    marginTop: 40,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  openingHoursContainer: {
    marginTop: 40,
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    paddingLeft: 15,
    paddingRight: 5,
  },
  labelText: {
    marginRight: 10,
    color: textColor[0],
    fontSize: 14,
    fontWeight: 600,
  },
  select: {
    marginLeft: 10,
    marginTop: 5,
    borderBottom: "1px solid #aaa",
    width: "80%",
    "& .MuiSelect-select.MuiSelect-select": {
      minWidth: 140,
      textAlign: "left",
      fontSize: 14,
    },
  },
  timpicker: {
    marginLeft: 10,
    marginTop: 5,
    borderBottom: "1px solid #aaa",
    width: "80%",
    "& .rdt input.form-control": {
      backgroundImage: "none",
      cursor: "pointer !important",
    },
    "& .rdtCounter .rdtBtn": {
      color: `${primaryColor[0]} !important`,
    },
    "& .rdtCounters .rdtCounter:last-child .rdtCount": {
      color: `${primaryColor[0]} !important`,
      borderColor: `${primaryColor[0]} !important`,
    },
    "& .rdtPicker": {
      width: "200px !important",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 18px",
    borderRadius: 3,
    margin: "20px auto",
    marginTop: 35,
    width: "90%",
    "& > span:first-child": {
      color: whiteColor,
      fontWeight: "bold",
    },
  },
  separator: {
    backgroundColor: textColor[0],
    width: 1,
    height: 20,
    marginLeft: "auto",
    marginRight: 20,
  },
  inputLabel: {
    display: "flex",
    width: "20%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    paddingLeft: 15,
    paddingRight: 5,
    marginTop: 10,
  },
  price: {
    fontWeight: 700,
    width: "80%",
    marginLeft: 10,
  },
  mapsContainer: {
    padding: 0,
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  maps: {
    height: 400,
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .gm-style": {
      width: "99% !important",
      // transition: "width 5s !important",
    },
  },
  mapsFix: {
    "& .gm-style": {
      width: "100% !important",
    },
  },
  findUsRoot: {
    width: "30%",
    padding: "20px 20px",
    display: "flex",
    backgroundSize: "65%",
    backgroundPosition: "50% 20%",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${locationBg})`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      backgroundSize: "auto 90%",
    },
  },
  findUsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "80%",
    marginTop: 60,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      marginBottom: 30,
    },
  },
  error: {
    fontSize: 12,
    fontWeight: 500,
    color: dangerColor[0],
    margin: "10px 0px",
    marginLeft: 15,
  },
  getDirections: {
    fontWeight: 600,
    color: primaryColor[0],
    "&:focus, &:hover, &:visited": {
      color: primaryColor[0],
    },
  },
  displayNone: {
    display: "none",
  },
  penalty: {
    color: primaryColor[0],
    display: "inline",
  },
});

export default locationDetailsStyle;
