import { makeStyles } from "@material-ui/core/styles";
import defaultNews from "assets/images/default-image.png";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import strings from "constants/strings";
import React from "react";
import { getDateString } from "utils";
import style from "./horizontalNewsCardStyle";

const useStyles = makeStyles(style);

export default function HorizontalNewsCard(props) {

  const { news, mAuto, more } = props;
  const classes = useStyles();

  const rootClasses = classNames({
    [classes.mAuto]: mAuto,
    [classes.root]: true,
  });

  return (
    <div className={rootClasses}>
      <GridContainer>
        <GridItem sm={4}>
          <div className={classes.imageContainer}>
            {news?.imgUrl ? (
              <img className={classes.image} src={news.imgUrl} alt="img1" />
            ) : (
                <img className={classes.image} src={defaultNews} alt="img1" />
              )}
          </div>
        </GridItem>
        <GridItem sm={8}>
          <div className={classes.infoContainer}>
            <div>
              <span className={classes.title}>{news?.title}</span>
              <span className={classes.date}>- {news?.createdAt ? getDateString(new Date(news?.createdAt)) : ""}</span>
            </div>
            <div className={classes.text}>{news.description}</div>
            <div className={classes.cardFooter}>
              <Button
                color="primary"
                className={classes.moreButton}
                onClick={() => (more())}
                round
              >{strings.moreAboutThis}</Button>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}