import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import strings from "constants/strings.js";
import PropTypes from "prop-types";
import React from "react";
import { formatAMPM, getDateString, convert12HrTo24Hr } from "utils/index.js";
import style from "./calendarStyle.js";

const useStyles = makeStyles(style);

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.isEqual = function (B) {
  var dateA = new Date(this.valueOf())
    .toLocaleString("en-GB", {
      timeZone: "Africa/Cairo",
    })
    .split(",")[0];
  var dateB = B.toLocaleString("en-GB", {
    timeZone: "Africa/Cairo",
  }).split(",")[0];

  return dateA === dateB;
};

export default function Calendar(props) {
  let { now, selectedDate, start, end, events, setSelectedDate, courtName } =
    props;
  end = end === "00:00" ? "24:00" : end;
  const [slots, setSlots] = React.useState([]);
  const [currentDate, setCurrentDate] = React.useState(
    selectedDate ? selectedDate : now
  );

  const classes = useStyles();

  const convertEventsToSlots = (events) => {
    let slots = [];

    let startIndex = parseInt(start.split(":")[0]) * 2;
    let endIndex = parseInt(end.split(":")[0]) * 2;

    for (var i = startIndex; i <= endIndex; i++) {
      let eventType = "";
      let hours = Math.floor(i / 2);
      let minutes = (i % 2) * 30;

      if (parseInt(start.split(":")[1]) > 0 && i === startIndex) {
        eventType = "disabled";
      }

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      slots.push({
        id: i - startIndex,
        eventType,
        time: `${hours}:${minutes}`,
      });
    }

    if (parseInt(end.split(":")[1]) > 0) {
      slots.push({
        id: slots.length,
        eventType: "disabled",
      });
    } else {
      slots.pop();
    }

    events.forEach((event) => {
      let fromDate = new Date(event.from);
      if (currentDate.isEqual(fromDate)) {
        let from = fromDate
          .toLocaleString("en-GB", {
            timeZone: "Africa/Cairo",
          })
          .split(",")[1];

        let fromIndex;

        if (from.charAt(from.length - 1) === "M") {
          const [hours, minutes] = convert12HrTo24Hr(from);
          fromIndex = parseInt(hours) * 2 + (parseInt(minutes) > 0 ? 1 : 0);
        } else {
          fromIndex =
            parseInt(from.split(":")[0]) * 2 +
            (parseInt(from.split(":")[1]) > 0 ? 1 : 0);
        }

        let toDate = new Date(event.to);

        let to = toDate
          .toLocaleString("en-GB", {
            timeZone: "Africa/Cairo",
          })
          .split(",")[1];

        let toIndex;
        if (from.charAt(from.length - 1) === "M") {
          const [hours, minutes] = convert12HrTo24Hr(to);
          toIndex = parseInt(hours) * 2 + (parseInt(minutes) > 0 ? 1 : 0);
        } else {
          toIndex =
            parseInt(to.split(":")[0]) * 2 +
            (parseInt(to.split(":")[1]) > 0 ? 1 : 0);
        }

        if (toIndex === 0) toIndex = 48;

        for (var i = fromIndex; i < toIndex; i++) {
          if (slots[i - startIndex])
            slots[i - startIndex].eventType = slots[i - startIndex].eventType
              ? slots[i - startIndex].eventType
              : event.type;
        }
      }
    });
    return slots;
  };

  React.useEffect(() => {
    setCurrentDate(selectedDate ? selectedDate : now);
  }, [selectedDate]);

  React.useEffect(() => {
    setSlots(convertEventsToSlots(events));
  }, [events, currentDate]);

  const generateHourElements = (start, end) => {
    let elements = [];

    let startIndex = parseInt(start.split(":")[0]);
    let endIndex =
      parseInt(end.split(":")[0]) + (parseInt(end.split(":")[1]) > 0 ? 1 : 0);

    for (var i = startIndex; i < endIndex; i++) {
      elements.push(
        <div key={i} className={classes.hourContainer}>
          <div className={classes.hourLegend}>{formatAMPM(`${i}:00`)}</div>
          <div className={classes.slotContainer}>
            <div
              className={
                classes.slot +
                " " +
                classes.dashedBorder +
                " " +
                (slots[i * 2 - startIndex * 2]?.eventType === "disabled"
                  ? classes.disabledBackground
                  : slots[i * 2 - startIndex * 2]?.eventType
                  ? classes.primaryLightBackground
                  : "")
              }
            />
            <div
              className={
                classes.slot +
                " " +
                (slots[i * 2 - startIndex * 2 + 1]?.eventType === "disabled"
                  ? classes.disabledBackground
                  : slots[i * 2 - startIndex * 2 + 1]?.eventType
                  ? classes.primaryLightBackground
                  : "")
              }
            />
          </div>
        </div>
      );
    }
    return elements;
  };

  const handleToday = () => {
    setCurrentDate(now);
    setSelectedDate(now.toISOString());
  };

  const handlePrev = () => {
    if (currentDate > now) {
      setCurrentDate(currentDate.addDays(-1));
      setSelectedDate(currentDate.addDays(-1).toISOString());
    }
  };

  const handleNext = () => {
    if (currentDate < now.addDays(6)) {
      setCurrentDate(currentDate.addDays(1));
      setSelectedDate(currentDate.addDays(1).toISOString());
    }
  };

  let hourElements = generateHourElements(start, end);

  return (
    <div className={classes.root}>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <h3 className={classes.title}>{getDateString(currentDate, false)}</h3>
          <h3 className={classes.title}>{courtName}</h3>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} className={classes.colorLabelsContainer}>
          <div className={classes.colorLabelContainer}>
            <h3 className={classes.colorLabel}>Booked</h3>
            <div
              className={
                classes.colorSample + " " + classes.primaryLightBackground
              }
            />
          </div>
          <div className={classes.colorLabelContainer}>
            <h3 className={classes.colorLabel}>Available</h3>
            <div className={classes.colorSample} />
          </div>
        </GridItem>
      </GridContainer>
      <div className={classes.buttonsContainer}>
        <Button
          onClick={handleToday}
          color="primary"
          className={classes.button}
          disabled={currentDate.isEqual(now)}
          round
        >
          Today
        </Button>
        <Button
          onClick={handlePrev}
          color="primary"
          className={classes.button}
          disabled={currentDate.isEqual(now)}
          round
        >
          Prev
        </Button>
        <Button
          onClick={handleNext}
          color="primary"
          className={classes.button}
          disabled={currentDate.isEqual(now.addDays(6))}
          round
        >
          Next
        </Button>
      </div>
      <GridContainer>
        <GridItem className={classes.calendarContainer}>
          <div className={classes.shortWeekDay}>
            {strings.weekDays[currentDate.getDay()]}
          </div>
          <div className={classes.dayGridContainer}>{hourElements}</div>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Calendar.defaultProps = {
  now: new Date(new Date().setHours(0, 0, 0, 0)),
  start: "09:00",
  end: "24:00",
  events: [],
  courtName: "",
};

Calendar.propTypes = {
  now: PropTypes.instanceOf(Date),
  start: PropTypes.string,
  end: PropTypes.string,
  events: PropTypes.array,
  courtName: PropTypes.string,
};
