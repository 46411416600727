import { primaryColor } from "assets/jss/material-kit-pro-react";
import {
  mlAuto,
  mrAuto,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const bookingCardStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  cardRoot: {
    borderRadius: "10px",
    maxWidth: 400,
    minWidth: 290,
    backgroundColor: whiteColor,
    padding: "30px",
    boxShadow: "0 5px 15px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  data: {
    borderLeft: "1px solid black",
    paddingLeft: "10px",
    fontWeight: "bold",
    width: "50%",
    display: "inline-block",
  },
  head: {
    fontWeight: "normal",
    width: "50%",
    display: "inline-block",
  },
  row: {
    borderBottom: "1px solid black",
    padding: "10px 0px",
  },
  penalty: {
    color: primaryColor[0],
  },
  button: {
    padding: 0,
    width: "100%",
    height: 30,
    marginTop: 10,
    marginBottom: -15,
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  },
  cancelNote: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
    display: "block",
    marginTop: 7,
    textAlign: "justify",
  },
});

export default bookingCardStyle;
