import {
  container,
  mlAuto,
  mrAuto,
  primaryColor,
  textColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const bookingCardStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    marginBottom: 30,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  shadowContainer: {
    width: "70%",
    height: "fit-content",
    margin: "15px 0px 40px 0px",
    padding: "20px",
    paddingTop: "15px",
    paddingBottom: "35px",
    objectFit: "contain",
    borderRadius: "9.5px",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)",
    position: "relative",
    backgroundColor: whiteColor,
    [theme.breakpoints.down("sm")]: {
      width: "85%",
      boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)",
      borderRadius: "9.5px",
      backgroundImage: "none",
      padding: "15px",
      paddingBottom: "35px",
    },
  },
  bookingCard: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  dateHeadingContainer: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  penalty: {
    color: primaryColor[0],
  },
  dateHeading: {
    color: primaryColor[0],
    fontSize: 17,
    fontWeight: 600,
    margin: 0,
    marginBottom: 10,
    textAlign: "center",
  },
  dateMonthHeading: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  dateText: {
    color: textColor[0],
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    marginBottom: 10,
    textAlign: "center",
  },
  dateMonthText: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  bookingInfoHeadingText: {
    fontSize: 17,
    fontWeight: 700,
  },
  bookingInfoLabelText: {
    fontSize: 12,
    fontWeight: 500,
    marginTop: 8,
    marginBottom: 5,
  },
  bookingInfoText: {
    fontSize: 12,
    fontWeight: 800,
    marginTop: 8,
    marginBottom: 5,
  },
  subHeadingText: {
    fontWeight: "600",
    marginTop: 0,
    marginBottom: 40,
    objectFit: "contain",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.23",
    letterSpacing: "normal",
    color: "#3d3d3",
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "white",
    paddingLeft: 15,
    paddingRight: 5,
    paddingTop: 0,
    marginTop: 5,
  },
  separator: {
    backgroundColor: textColor[0],
    width: 2,
    height: 20,
    marginRight: 7,
    marginLeft: 7,
  },

  horizontalSeparator: {
    backgroundColor: textColor[0],
    width: "100%",
    height: 0.5,
  },
  cardContainer: {
    margin: 0,
  },
});

export default bookingCardStyle;
