import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import style from "./bookingCardStyle.js";

const useStyles = makeStyles(style);

export default function BookingCard() {
  const classes = useStyles();

  return (
    <div>
      <Skeleton
        variant="rect"
        style={{ maxWidth: "400px" }}
        width="100%"
        height={400}
      ></Skeleton>
    </div>
  );
}
