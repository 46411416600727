import React from "react";
import HorizontalNewsCard from "./HorizontalNewsCard/HorizontalNewsCard";
import VerticalNewsCard from "./VerticalNewsCard/VerticalNewsCard";

export default function NewsCard(props) {

  const { news, horizontal, long, mAuto, more } = props;

  return (!horizontal || window.innerWidth < 768) ? (
    <VerticalNewsCard long={long} news={news} mAuto={mAuto} more={more} />
  ) :
    (
      <HorizontalNewsCard news={news} mAuto={mAuto} more={more} />
    );
}