export default {
  AuthMode: {
    SIGNUP: 0,
    LOGIN: 1,
  },
  EmailVerificationStatus: {
    INVALID: 0,
    VERIFIED: 1,
    ALREADY_VERIFIED: 2,
    EXPIRED: 3,
  },
  ReservationTypes: {
    NORMAL: 0,
    CANCELLED: 1,
    NOSHOW: 2,
    PAID: 3,
  },
};
