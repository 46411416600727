import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import classNames from "classnames";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import strings from "constants/strings";
import React from "react";
import { useForm } from "react-hook-form";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./forgotPasswordStyle";

const useStyles = makeStyles(styles);

export default function ForgotPassword(props) {
  const classes = useStyles();

  const [email, setEmail] = React.useState();
  const [
    showPasswordResetMessage,
    setShowPasswordResetMessage,
  ] = React.useState(false);

  const [
    sendRequest,
    isLoading,
    error,
    paramError,
    clearError,
  ] = useHttpClient();

  const { register, handleSubmit, errors, getValues, setValue } = useForm();

  const validationRules = {
    email: {
      required: "This field is required.",
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email not valid",
      },
      maxLength: {
        value: 50,
        message: "Email cannot exceed 50 characters.",
      },
    },
  };

  const handleForgotPassword = async () => {
    clearError();
    try {
      await sendRequest(api.forgotPassword(getValues("email")));
      setShowPasswordResetMessage(true);
    } catch (err) {}
  };

  const formClasses = classNames({
    [classes.formContainer]: true,
  });

  return (
    <div className={classes.root}>
      {showPasswordResetMessage ? (
        <div
          className={
            classes.resetPasswordTitle + " " + classes.resetPasswordSucceed
          }
        >
          <p className={classes.title}>{strings.firstTitleForgotSucceed}</p>
          <p className={classes.email}>{email}</p>
          <p className={classes.title}>{strings.secondTitleForgotSucceed}</p>
        </div>
      ) : (
        <div className={classes.root + " " + classes.innerRoot}>
          <span className={classes.resetPasswordTitle}>
            We'll send password reset instructions to the email address
            associated with your account
          </span>

          <form
            className={classes.form}
            onSubmit={handleSubmit(handleForgotPassword)}
          >
            <GridContainer justify="center" className={classes.rootContainer}>
              <GridItem xs={12} sm={12} md={12} className={formClasses}>
                <div className={classes.enterEmailTitle}>
                  Enter Email Address
                </div>
                <CustomInput
                  iconStart={<EmailIcon />}
                  style={{ margin: 0, paddingTop: 0 }}
                  error={errors.email?.message || paramError("email")}
                  id="email"
                  inputRef={register(validationRules.email)}
                  inputProps={{
                    onChange: (event) => {
                      setValue("email", event.target.value);
                      setEmail(event.target.value);
                    },
                    placeholder: "Email",
                    name: "email",
                  }}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputContainerClasses={classes.inputContainer}
                  filled
                />

                <Button
                  type="submit"
                  className={classes.resetPasswordButton}
                  loading={isLoading}
                >
                  Reset Password
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </div>
      )}
    </div>
  );
}
