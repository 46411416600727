import {
  dangerColor,
  mlAuto,
  mrAuto,
  primaryColor,
  textColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const personalInfoFormStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },

  labelText: {
    marginTop: 20,
    fontWeight: 600,
    fontSize: 15,
  },
  button: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 30px",
    borderRadius: 5,
    "& > span:first-child": {
      color: whiteColor,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveButton: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  separator: {
    backgroundColor: textColor[0],
    width: 1,
    height: 20,
    marginRight: 7,
  },
  where: {
    marginRight: 10,
    color: textColor[0],
    fontSize: 14,
    fontWeight: 600,
  },
  customTextStyle: {
    paddingTop: 0,
    margin: 0,
  },
  labelStyle: {
    marginBottom: 20,
    marginLeft: 20,
    display: "flex",
    alignContent: "center",
  },
  labelText: {
    fontWeight: 500,
    fontSize: "16px",
  },
  inputStyle: {
    paddingLeft: 0,
    marginRight: 20,

    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
      paddingLeft: 15,
      marginRight: 40,
      marginBottom: 20,
    },
  },
  gridContainerStyle: {
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  customTextStyle: {
    marginTop: 0,
    padding: 0,
  },
  responseMessage: {
    color: primaryColor[0],
    fontSize: 20,
    fontWeight: 300,
    paddingLeft: 5,
    marginLeft: 20,
    opacity: 0,
    transition: "0.3s all",
  },
  responseMessageVisible: {
    opacity: 1,
  },
  dangerText: {
    color: dangerColor[0],
  },
  formControl: {
    margin: 0,
    paddingTop: 0,
  },
});

export default personalInfoFormStyle;
