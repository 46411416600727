import { whiteColor } from "assets/jss/material-kit-pro-react";
import { pageRoot } from "assets/jss/material-kit-pro-react";
import {
  container,
  primaryColor,
  secondaryColor,
} from "assets/jss/material-kit-pro-react";

const emailConfirmationStyle = (theme) => ({
  root: {
    // marginTop: 175,
    // padding: "0px 0px",
    // position: "relative",
    // [theme.breakpoints.down("sm")]: {
    //   padding: "60px 0px",
    // },
    ...pageRoot,
  },
  container: {
    ...container,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      marginTop: 70,
    },
  },
  title: {
    fontSize: 14,
    marginTop: 15,
    color: secondaryColor[0],
    textAlign: "center",
  },
  emailTitle: {
    fontSize: 14,
    marginTop: 15,
    color: primaryColor[0],
    textAlign: "center",
  },
  resendEmailContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "center",
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[0],
    },
    textAlign: "center",
  },
  pointer: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  boxBackground: {
    width: 665,
    height: "42%",
    marginTop: 20,
    marginBottom: 60,
    marginRight: "auto",
    marginLeft: "auto",
    padding: "90px 117px 90px 117px",
    objectFit: "contain",
    borderRadius: "32.5px",
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    backgroundColor: "var(--very-light-pink)",
    backgroundSize: "279px 279px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: whiteColor,
    [theme.breakpoints.down("sm")]: {
      padding: "80px 20px",
      boxShadow: "none",
      backgroundColor: "transparent",
      width: "100%",
      borderRadius: 0,
    },
  },
});

export default emailConfirmationStyle;
