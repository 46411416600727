import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/`,
  withCredentials: true,
});

const setAuthToken = (token) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthToken = () => {
  axiosInstance.defaults.headers.common.Authorization = "";
};

const getNews = (page, limit) =>
  axiosInstance.get(`news?page=${page}&limit=${limit}`);

const getLatestNews = () => axiosInstance.get(`news/latest`);

const getAllLocations = () => axiosInstance.get(`locations`);

const getFeaturedLocations = () => axiosInstance.get(`locations/featured`);

const getUserPenalty = () => axiosInstance.get(`users/penalty`);

const getLocationById = (id) => axiosInstance.get(`locations/${id}`);

const getCourtById = (id) => axiosInstance.get(`courts/${id}`);

const cancelBooking = (id) => axiosInstance.post(`reservations/${id}/cancel`);

const bookCourt = (id, bookingData) =>
  axiosInstance.post(`/courts/${id}/reserve`, bookingData);

const subscribeToNewsLetter = (email) =>
  axiosInstance.post("newsletter/subscribe", { email });

const signUpWithEmail = (authData) =>
  axiosInstance.post("auth/user/signup", authData);

const signInWithEmail = (authData) =>
  axiosInstance.post("auth/user/signin", authData);

const checkResendEmailConfirmation = () =>
  axiosInstance.get(`auth/user/email/checkresend`);

const resendEmailConfirmation = () =>
  axiosInstance.get(`auth/user/email/resendconfirmation`);

const verifyEmail = (token) =>
  axiosInstance.patch(`auth/user/email/verify`, { token });

const forgotPassword = (email) =>
  axiosInstance.put(`auth/user/forgotpassword`, { email });

const checkResetPassword = (token) =>
  axiosInstance.get(`auth/user/checkresetpassword?token=${token}`);

const resetPassword = (data) =>
  axiosInstance.put(`auth/user/resetpassword`, data);

const getUserInfo = () => axiosInstance.get(`/users/info`);

const updateUserInfo = (userData) =>
  axiosInstance.patch(`/users/info`, userData);

const updateUserPassword = (userPasswords) =>
  axiosInstance.patch(`/users/password`, userPasswords);

const contactUs = (formData) => axiosInstance.post("/contactus", formData);

const getActiveBookings = () => axiosInstance.get("/reservations/active");

const getBookingsHistory = () => axiosInstance.get("/reservations/history");

export default {
  setAuthToken,
  clearAuthToken,
  getNews,
  getLatestNews,
  signUpWithEmail,
  signInWithEmail,
  checkResendEmailConfirmation,
  resendEmailConfirmation,
  verifyEmail,
  forgotPassword,
  checkResetPassword,
  resetPassword,
  getAllLocations,
  getLocationById,
  getCourtById,
  bookCourt,
  getUserInfo,
  updateUserInfo,
  updateUserPassword,
  contactUs,
  getActiveBookings,
  getBookingsHistory,
  getFeaturedLocations,
  getUserPenalty,
  cancelBooking,
  subscribeToNewsLetter,
};
