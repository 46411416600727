import {
  blackColor,
  hexToRgb,
  primaryColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

const forgotPasswordStyle = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  innerRoot: {
    width: "100%",
    padding: 35,
  },
  form: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  resetPasswordButton: {
    "& > span:first-child": {
      color: whiteColor,
    },
    padding: 0,
    height: 30,
    width: "100%",
    backgroundColor: primaryColor[0],
    fontFamily: "sans-serif",
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formContainer: {
    transition: "0.3s all",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },
  rootContainer: { width: "100%" },
  enterEmailTitle: {
    width: "100%",
    marginBottom: 5,
    display: "flex",
    justifyContent: "flex-start",
    color: primaryColor[0],
    fontSize: 12,
  },
  resetPasswordTitle: {
    textAlign: "center",
    fontSize: 12,
    color: blackColor,
    width: 260,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  email: {
    textAlign: "center",
    fontSize: 12,
    color: primaryColor[0],
  },
  formControl: {
    margin: 0,
    paddingTop: 0,
  },
  inputContainer: {
    margin: "10px 0",
  },
  title: { color: blackColor },
  resetPasswordSucceed: { marginBottom: 60, marginTop: 30 },
});

export default forgotPasswordStyle;
