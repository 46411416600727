import { dangerColor, whiteColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
  primaryColor,
  textColor,
} from "assets/jss/material-kit-pro-react.js";

const passwordFormStyle = (theme) => ({
  mrAuto,
  mlAuto,

  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileName: {
    fontSize: 16,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  section: {
    padding: "40px 40px",
  },
  sectionBorder: {
    borderBottom: "1px solid #aaa",
  },
  heading: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      fontSize: "1.5rem",
    },
  },
  responseMessage: {
    color: primaryColor[0],
    fontSize: 14,
    fontWeight: 100,
    paddingLeft: 5,
    opacity: 0,
    transition: "0.3s all",
  },
  responseMessageVisible: {
    opacity: 1,
  },
  dangerText: {
    color: dangerColor[0],
  },
  coursesContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 30px",
    borderRadius: 5,
    "& > span:first-child": {
      color: whiteColor,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "white",
    boxShadow: "0 0 2.5px 0 rgba(0, 0, 0, 0.4);",
    borderRadius: 25,
    paddingLeft: 15,
    paddingRight: 5,
    paddingTop: 0,
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: 0,
    },
  },
  saveButton: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  buttonContainer: {
    width: "100%",
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  },
  textFieldMarginBottom: {
    marginBottom: 10,
  },
  rootContainer: {
    width: "100%",
  },
  textField: {
    width: "30%",
    height: 35,
  },

  labelStyle: {
    marginBottom: 20,
    marginLeft: 20,
    fontWeight: 400,
    fontSize: 20,
    display: "flex",
    alignContent: "center",
  },
  labelText: {
    fontWeight: 500,
    fontSize: "16px",
  },
  inputStyle: {
    paddingLeft: 0,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
      paddingLeft: 15,
      marginRight: 40,
      marginBottom: 20,
    },
  },
  gridContainerStyle: {
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  iconWrapper: {
    float: "left",
    marginTop: "5px",
    marginRight: "10px",
    position: "relative",
    zIndex: 2,
  },
  iconButton: {
    padding: "0px 0px 5px 7px",
  },
  responseMessage: {
    color: primaryColor[0],
    fontSize: 20,
    fontWeight: 300,
    paddingLeft: 5,
    marginLeft: 20,
    opacity: 0,
    transition: "0.3s all",
  },
  responseMessageVisible: {
    opacity: 1,
  },
  dangerText: {
    color: dangerColor[0],
  },
  helperTextStyle: {
    color: dangerColor[0] + " !important",
    fontFamily: "Montserrat",
    marginTop: 5,
    marginLeft: 15,
    fontSize: 15,
    fontWeight: 400,
  },
  formControl: {
    margin: 0,
    paddingTop: 0,
  },
});

export default passwordFormStyle;
