import {
  blackColor,
  grayColor,
  hexToRgb,
  infoColor,
  primaryColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

const authModalStyle = (theme) => ({
  authModalContainer: {
    margin: 0,
  },
  authModalBody: {
    backgroundColor: infoColor[8],
  },
  authModalBodyContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  orBorderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0",//margin: "25px 0",
    // height: 25,
    width: "90%",
  },
  orParagraph: {
    color: "black",
    fontWeight: "bold",
    margin: "0 15px 0 15px",
  },
  orBorder: {
    width: "50%",
    height: 0,
    borderBottom: `solid 2px ${primaryColor[0]}`,
  },
  noPadding: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      height: "inherit",
    },
  },
  buttonOutlined: {
    position: "relative",
    float: "right !important",
    width: "90%",
    padding: 0,
    fontWeight: "bold",
    marginBottom: 15,
    fontSize: "12px",
    textTransform: "capitalize",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    backgroundColor: "transparent",
    height: 30,
    borderRadius: 5,
    display: "inline-flex",
    color: blackColor,
    "& > span:first-child": {
      color: blackColor,
    },
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: grayColor[27],
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      // height: 40,
      fontSize: 14,
      minWidth: 213,
      textAlign: "left",
      // fontSize: "0.75rem",
      "& > span:first-child": {
        justifyContent: "center",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(grayColor[2]) + ", 0.8)",
      color: blackColor,
    },
    "&:focus": {
      color: blackColor,
    },
  },
  socials: {
    position: "absolute",
    left: "15px",
    fontSize: "1.1rem",
  },
  faGoogle: {
    background:
      "conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat",
    "-webkit-background-clip": "text",
    backgroundClip: "text",
    color: "transparent",
    "-webkit-text-fill-color": "transparent",
  },
  faFacebook: {
    color: whiteColor,
    fontWeight: 100,
  },
  infoArea: {
    color: blackColor,
    fontWeight: 400,
    fontSize: 10,
    textAlign: "center",
    margin: "-10px auto 15px auto",
  },
  link: {
    cursor: "pointer",
    color: primaryColor[0],
    fontWeight: "bold",
    "&:hover,&:focus": {
      textDecoration: "underline",
      color: primaryColor[0],
    },
  },
  authModalHeaderImage: {
    width: 500, // 600
    height: 42.5, // 40,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  closeButton: {
    top: 20,
    right: 6,
    opacity: 1,
    "&:hover": {
      opacity: 0.5,
    },
  },
  closeButtonIcon: {
    width: "32px !important",
    height: "32px !important",
    fill: "white",
  },
  headerTitle: {
    position: "absolute",
    top: -2,
    left: 10,
    fontWeight: 700,
  },
  modalBodyClass: {
    width: 500,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  facebookButton: {
    backgroundColor: "#3b5998",
    borderColor: "#3b5998",
    color: whiteColor,
    "& > span:first-child": {
      color: whiteColor,
    },
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb("#3b5998") + ", 0.8)",
      color: whiteColor,
    },
    "&:focus": {
      color: whiteColor,
    },
  },
  googleButton: {
    marginBottom: 30,
  },
  socialButtonAnchor: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});

export default authModalStyle;
