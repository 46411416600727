import { textColor, whiteColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const horizontalNewsCardStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  root: {
    borderRadius: "10px",
    padding: "0px",
    width: "90%",
    margin: "20px",
    backgroundColor: whiteColor,
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
  },
  imageContainer: {
    padding: "20px",
    height: "270px",
    width: "100%",
  },
  image: {
    borderRadius: "10px",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center center",
  },
  infoContainer: {
    height: "100%",
    padding: "20px 30px",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
  },
  date: {
    fontWeight: "500",
    fontSize: "0.8rem",
    display: "inline-block",
    color: "gray",
  },
  title: {
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "1.0rem",
    marginBottom: "10px",
    marginRight: "10px",
    display: "inline-block",
  },
  text: {
    color: textColor[0],
    fontSize: "0.8rem",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "500",
  },
  cardFooter: {
    padding: "15px",
    marginTop: "auto",
    textAlign: "center",
  },
  moreButton: {
    alignSelf: "flex-end",
    textAlign: "center",
    display: "block",
    width: "100%",
    padding: "10px 20px",
    "& > span:first-child": {
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  }
});

export default horizontalNewsCardStyle;
