import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import defaultImage from "assets/images/default-image.png";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import style from "./locationCardStyle.js";

const useStyles = makeStyles(style);

export default function LocationCard(props) {
  const classes = useStyles();
  const history = useHistory();

  const { mAuto, location } = props;
  const rootClasses = classNames({
    [classes.mAuto]: mAuto,
    [classes.root]: true,
  });

  const imageContainerClasses = classNames({
    [classes.imageContainer]: true,
  });

  const goToLocation = () => {
    history.push({
      pathname: "location",
      search: `?q=${location?.id}`,
    });
  };

  return (
    <div className={rootClasses}>
      <div className={classes.container}>
        <Link to={`/location?q=${location?.id}`}>
          <div className={imageContainerClasses}>
            {location?.imgUrl ? (
              <img
                className={classes.image}
                src={location?.imgUrl}
                alt="img1"
              />
            ) : (
              <img className={classes.image} src={defaultImage} alt="img1" />
            )}
            <div className={classes.nameContainer}>
              <h6 className={classes.locationTitle}>{location?.name}</h6>
            </div>
          </div>
        </Link>
        <div className={classes.infoContainer}>
          <div className={classes.cardFooter}>
            <div className={classes.addressContainer}>
              <LocationOnIcon className={classes.icon} />
              <span className={classes.address}>{location?.address}</span>
            </div>
            <Button
              color="primary"
              className={classes.bookButton}
              onClick={goToLocation}
            >
              {"Book Now"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
