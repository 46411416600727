import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import style from "./bookNowStyle.js";
import SelectInput from "components/SelectInput/SelectInput";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api.js";
import Fade from "react-reveal/Fade";

const useStyles = makeStyles(style);

export default function BookNow() {
  const classes = useStyles();
  const history = useHistory();
  const [sendRequest] = useHttpClient();

  const [locations, setLocations] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState(0);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getAllLocations());
        setLocations(
          response.data.map((location) => ({
            name: location.name,
            value: location.id,
          }))
        );
      } catch (err) {}
    })();
  }, []);

  const handleSelectLocaiton = (event) => {
    setSelectedLocation(event.target.value);
  };

  const goToBooking = () => {
    if (selectedLocation) {
      setIsError(false);
      history.push({
        pathname: "/location",
        search: `?q=${selectedLocation}`,
      });
    } else {
      setIsError(true);
    }
  };

  return (
    <div className={classes.root}>
      <h4 className={classes.heading}>
        Book your <span className={classes.primaryColor}> padel court </span>{" "}
        now!
      </h4>
      <div className={classes.selectContainer}>
        <div className={classes.where}>Where</div>
        <span className={classes.separator} />
        <SelectInput
          id="locations"
          selectStyle={classes.select}
          placeholder={"Select a location"}
          data={locations}
          value={selectedLocation}
          onSelect={handleSelectLocaiton}
        />
      </div>
      <Fade collapse when={isError}>
        <div
          className={classes.error}
        >
          Please select a location
        </div>
      </Fade>
      <Button color="primary" className={classes.button} onClick={goToBooking}>
        Check Availability
      </Button>
    </div>
  );
}
