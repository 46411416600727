import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import style from "./subscribeFormStyle.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Fade from "react-reveal/Fade";
import api from "services/api.js";
import { useHttpClient } from "shared/hooks/http-hook";
import { checkValidity } from "utils/index.js";

const useStyles = makeStyles(style);

export default function SubscrubeForm() {
  const classes = useStyles();
  const [sendRequest, loading] = useHttpClient();
  const [email, setEmail] = React.useState({
    value: "",
    error: null,
    validation: {
      required: true,
      minLength: 1,
      maxLength: 100,
      email: true,
    },
  });
  const [response, setResponse] = React.useState("");

  const handleEmailChange = (event) => {
    const updatedEmailElement = { ...email };
    updatedEmailElement.value = event.target.value;
    setEmail(updatedEmailElement);
  };

  const handleSubscribe = async () => {
    let updatedElement = { ...email };
    updatedElement.error = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    setEmail(updatedElement);
    if (!updatedElement.error) {
      try {
        const subscribeResponse = await sendRequest(
          api.subscribeToNewsLetter(email.value)
        );
        setTimeout(() => {
          setResponse("Thank you for subscribing");
        }, 1000);
        setTimeout(() => {
          setResponse("");
        }, 5000);
      } catch (err) {
        setTimeout(() => {
          setResponse(err.response.data.message);
        }, 1000);
        setTimeout(() => {
          setResponse("");
        }, 5000);
      }
    }
  };

  return (
    <div className={classes.subscribeRoot}>
      <div className={classes.container + " " + classes.subscribeContainer}>
        <div className={classes.skew + " " + classes.primaryBackground}>
          <div
            className={classes.subscribeHeadingContainer + " " + classes.unskew}
          >
            <h4 className={classes.subscribeHeading}>
              Stay in touch with Padel Hub Egypt
            </h4>
            <div className={classes.subscribeDescription}>
              Sign up to Padel Hub Egypt newsletter and be the first one to know
              about special offers or news.
            </div>
          </div>
        </div>
        <div className={classes.subscribeFormContainer}>
          <CustomInput
            error={email.error ? true : false}
            inputProps={{
              placeholder: "Email",
              onChange: handleEmailChange,
            }}
            filled
            formControlProps={{
              fullWidth: true,
              className: classes.input,
            }}
          />
          <Button
            round
            color="primary"
            className={classes.button + " " + classes.subscribe}
            onClick={handleSubscribe}
            loading={loading}
          >
            Subscribe
          </Button>
          <Fade collapse when={Boolean(response)}>
            <div className={classes.subscribeSuccessMessage}>{response}</div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
