import { textColor } from "assets/jss/material-kit-pro-react";
import { secondaryColor, dangerColor } from "assets/jss/material-kit-pro-react";

const selectInputStyle = {
  selectFormControl: { textAlign: "center" },
  select: {
    color: textColor[0],
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  labelRootError: {
    color: dangerColor[0] + " !important",
  },
};

export default selectInputStyle;
