import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { useForm } from "react-hook-form";
import style from "./passwordFormStyle.js";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api";

const useStyles = makeStyles(style);

export default function PasswordForm(props) {
  const { user } = props;
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [sendRequest] = useHttpClient();
  const [responseMessage, setResponseMessage] = React.useState({
    message: ".",
    visible: false,
    error: false,
  });

  const toggleShowCurrentPassword = () => {
    const current = showCurrentPassword;
    setShowCurrentPassword(!current);
  };

  const toggleShowNewPassword = () => {
    const current = showNewPassword;
    setShowNewPassword(!current);
  };

  const toggleShowConfirmPassword = () => {
    const current = showConfirmPassword;
    setShowConfirmPassword(!current);
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    setError,
    getValues,
  } = useForm();

  const validationRules = {
    currentPassword: {
      required: "This field is required.",
    },
    newPassword: {
      required: "This field is required.",
      minLength: {
        value: 8,
        message: "Password should be at least 8 characters.",
      },
      maxLength: {
        value: 60,
        message: "Password cannot exceed 60 characters.",
      },
      validate: {
        notSameAsCurrentPassword: (value) =>
          value !== getValues("currentPassword") ||
          "New password must not be used before",
      },
    },
    confirmPassword: {
      required: "This field is required.",
      minLength: {
        value: 8,
        message: "Password should be at least 8 characters.",
      },
      maxLength: {
        value: 60,
        message: "Password cannot exceed 60 characters.",
      },
      validate: {
        notSameAsNewPassword: (value) =>
          value === getValues("newPassword") ||
          "Must be the same as the new password",
      },
    },
  };
  const handleUpdatePassword = async () => {
    try {
      setLoading(true);
      let info = {
        currentPassword: getValues("currentPassword"),
        newPassword: getValues("newPassword"),
      };
      const res = await sendRequest(api.updateUserPassword(info));
      setLoading(false);
      setTimeout(() => {
        setResponseMessage({
          visible: true,
          error: false,
          message: "Information updated successfully",
        });
        setValue("currentPassword", null);
        setValue("newPassword", null);
        setValue("confirmPassword", null);
      }, 1000);
      setTimeout(() => {
        setResponseMessage({
          ...responseMessage,
          visible: false,
          error: false,
        });
      }, 5000);
    } catch (err) {
      setLoading(false);
      if (err.response?.data?.errors?.length) {
        err.response.data.errors.forEach((error) => {
          // console.log(error);
          if (error.location && error.location === "body") {
            setError(error.param, {
              type: "manual",
              message: error.msg,
            });
          }
        });
      } else {
        setLoading(false);
        setTimeout(() => {
          setResponseMessage({
            visible: true,
            error: true,
            message: "Something went wrong",
          });
        }, 1000);
        setTimeout(() => {
          setResponseMessage({
            ...responseMessage,
            visible: false,
            error: false,
          });
        }, 5000);
      }
    }
  };

  const classes = useStyles();
  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(handleUpdatePassword)}
    >
      <GridContainer className={classes.gridContainerStyle}>
        <GridItem xs={12} sm={12} md={3} className={classes.labelStyle}>
          <h4 className={classes.labelText}>Current Password</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={5} className={classes.inputStyle}>
          <CustomInput
            error={errors.currentPassword?.message}
            inputRef={register(validationRules.currentPassword)}
            inputProps={{
              onChange: (event) => {
                setValue("currentPassword", event.target.value);
              },
              placeholder: "Current Password",
              name: "currentPassword",
            }}
            id="currentPassword"
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            type={showCurrentPassword ? "text" : "password"}
            filled
            iconEnd={
              <IconButton
                onClick={toggleShowCurrentPassword}
                className={classes.iconButton}
                disabled={false}
              >
                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
          />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.gridContainerStyle}>
        <GridItem xs={12} sm={12} md={3} className={classes.labelStyle}>
          <h4 className={classes.labelText}>New Password</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={5} className={classes.inputStyle}>
          <CustomInput
            error={errors.newPassword?.message}
            inputRef={register(validationRules.newPassword)}
            inputProps={{
              onChange: (event) => {
                setValue("newPassword", event.target.value);
              },
              placeholder: "New Password",
              name: "newPassword",
            }}
            id="newPassword"
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            type={showNewPassword ? "text" : "password"}
            filled
            iconEnd={
              <IconButton
                onClick={toggleShowNewPassword}
                className={classes.iconButton}
                disabled={false}
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
          />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.gridContainerStyle}>
        <GridItem xs={12} sm={12} md={3} className={classes.labelStyle}>
          <h4 className={classes.labelText}>Confirm New Password</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={5} className={classes.inputStyle}>
          <CustomInput
            error={errors.confirmPassword?.message}
            inputRef={register(validationRules.confirmPassword)}
            inputProps={{
              onChange: (event) => {
                setValue("confirmPassword", event.target.value);
              },
              placeholder: "Confirm Password",
              name: "confirmPassword",
            }}
            id="confirmPassword"
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            type={showConfirmPassword ? "text" : "password"}
            filled
            iconEnd={
              <IconButton
                onClick={toggleShowConfirmPassword}
                className={classes.iconButton}
                disabled={false}
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={1}>
          <div className={classes.saveButton}>
            <Button
              type="submit"
              color="primary"
              className={classes.button}
              round
              loading={loading}
            >
              Save
            </Button>
          </div>
        </GridItem>
      </GridContainer>
      {responseMessage.visible ? (
        <GridContainer className={classes.gridContainerStyle}>
          <GridItem xs={12} sm={12} md={8} className={classes.labelStyle}>
            <div
              className={
                classes.responseMessage +
                " " +
                classes.marginBottomDesktop +
                " " +
                (responseMessage.visible
                  ? classes.responseMessageVisible + " "
                  : "") +
                (responseMessage.error ? classes.dangerText : "")
              }
            >
              {responseMessage.message}
            </div>
          </GridItem>
        </GridContainer>
      ) : (
        <div></div>
      )}
    </form>
  );
}
