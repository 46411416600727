import { makeStyles } from "@material-ui/core/styles";
import defaultNews from "assets/images/default-image.png";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import strings from "constants/strings.js";
import React from "react";
import { getDateString } from "utils";
import style from "./verticalNewsCardStyle.js";

const useStyles = makeStyles(style);

export default function VerticalNewsCard(props) {

  const { news, long, mAuto, more } = props;
  const classes = useStyles();

  const rootClasses = classNames({
    [classes.mAuto]: mAuto,
    [classes.root]: true,
  });

  const imageContainerClasses = classNames({
    [classes.longCard]: long,
    [classes.imageContainer]: true,
  });

  const textClasses = classNames({
    [classes.text]: true,
    [classes.longText]: long,
  });

  return (
    <div className={rootClasses}>
      <div className={classes.container}>
        <div className={imageContainerClasses}>
          {news?.imgUrl ? (
            <img className={classes.image} src={news.imgUrl} alt="img1" />
          ) : (
              <img className={classes.image} src={defaultNews} alt="img1" />
            )}
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.date}>- {news?.createdAt ? getDateString(new Date(news?.createdAt)) : ""}</div>
          <h6 className={classes.title}>{news?.title}</h6>
          <div className={textClasses}>{news?.description}</div>
          <div className={classes.cardFooter}>
            <Button
              color="primary"
              className={classes.moreButton}
              onClick={() => more()}
              round
            >{strings.moreAboutThis}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}