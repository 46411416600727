import { dangerColor } from "assets/jss/material-kit-pro-react";
import { primaryColor } from "assets/jss/material-kit-pro-react";
import { textColor } from "assets/jss/material-kit-pro-react";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const bookNowStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    borderRadius: 20,
    backgroundColor: whiteColor,
    height: "fit-content",
    width: "fit-content",
    padding: "20px 45px",
    boxShadow: "2.5px 2.5px 2.5px 0 rgba(0, 0, 0, 0.4);",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    boxShadow: "0 0 2.5px 0 rgba(0, 0, 0, 0.4);",
    borderRadius: 3,
    paddingLeft: 15,
    paddingRight: 5,
  },
  where: {
    marginRight: 10,
    color: textColor[0],
    fontSize: 14,
    fontWeight: 600,
  },
  select: {
    marginLeft: 10,
    marginTop: 5,
    "& .MuiSelect-select.MuiSelect-select": {
      minWidth: 140,
      textAlign: "left",
      fontSize: 14,
    },
  },
  separator: {
    backgroundColor: textColor[0],
    width: 1,
    height: 20,
  },
  heading: {
    marginBottom: 20,
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  primaryColor: {
    color: primaryColor[0],
  },
  error: {
    fontSize: 12,
    fontWeight: 500,
    color: dangerColor[0],
    // display: "none",
  },
  button: {
    padding: 0,
    width: "100%",
    height: 30,
    marginTop: 10,
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  },
});

export default bookNowStyle;
