import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router";
import style from "./bookingCardStyle";
import React from "react";

const useStyles = makeStyles(style);

function BookingCard(props) {
  const { booking, mAuto, withCancel, cancelHandler, isConfirmation } = props;
  const classes = useStyles();

  const rootClasses = classNames({
    [classes.mAuto]: mAuto,
    [classes.cardRoot]: true,
  });

  let history = useHistory();

  const date = booking ? new Date(booking.from) : new Date();
  
  const bookingDate = new Intl.DateTimeFormat("en-GB", { timeZone: "Africa/Cairo" }).format(date);

  const bookingTime = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Africa/Cairo"
  }).format(date);

  const bookingDay = new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    timeZone: "Africa/Cairo"
  }).format(date);

  return (
    <div className={rootClasses}>
      <h4>Bookings Information</h4>
      <div className={classes.row}>
        <span className={classes.head}>Where</span>
        <span className={classes.data}>{booking?.locationName}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.head}></span>
        <span className={classes.data}>{booking?.courtName}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.head}>Date</span>
        <span className={classes.data}>{bookingDay + ", " + bookingDate}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.head}>Time</span>
        <span className={classes.data}>{bookingTime}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.head}>Duration</span>
        <span className={classes.data}>{`${booking?.duration} minutes`}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.head}>Booking No.</span>
        <span className={classes.data}>{booking?.referenceId}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.head}>Payment</span>
        <span className={classes.data}>
          {`${booking?.price} LE`}
          {booking?.penaltyAdded > 0 ? (
            <div className={classes.penalty}>
              + {parseInt(booking?.penaltyAdded)} LE (penalty)
            </div>
          ) : null}
        </span>
      </div>
      {isConfirmation ? (
        <div>
          <Button
            color="primary"
            className={classes.button}
            style={{ marginTop: 20 }}
            onClick={() =>
              history.push({
                pathname: "/mybookings",
              })
            }
          >
            Go to bookings
          </Button>
        </div>
      ) : null}
      {withCancel ? (
        <div>
          <span className={classes.cancelNote}>
            Note: You can only cancel your booking before{" "}
            {process.env.REACT_APP_RESERVATION_CANCEL_MARGIN} hours from the
            starting time. Otherwise, please contact the support.
          </span>
          <Button
            color="danger"
            className={classes.button}
            onClick={cancelHandler}
          >
            Cancel Booking
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default BookingCard;
