/*eslint-disable*/
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import LockIcon from "@material-ui/icons/Lock";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./resetPasswordStyle";

const useStyles = makeStyles(styles);

export default function ResetPassword({ ...rest }) {
  const history = useHistory();
  const location = useLocation();

  const [pageLoading, setPageLoading] = React.useState(true);
  const [token, setToken] = React.useState(null);
  const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState(false);

  const { register, handleSubmit, errors, getValues, setValue } = useForm();
  const [
    sendRequest,
    isLoading,
    error,
    paramError,
    clearError,
  ] = useHttpClient();

  useEffect(() => {
    (async () => {
      const token = new URLSearchParams(location.search).get("token");
      if (!token) history.push("/");
      try {
        await sendRequest(api.checkResetPassword(token));
        setToken(token);
      } catch (err) {
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const classes = useStyles();

  const validationRules = {
    password: {
      required: "This field is required.",
      minLength: {
        value: 8,
        message: "Password should be at least 8 characters.",
      },
      maxLength: {
        value: 60,
        message: "Password cannot exceed 60 characters.",
      },
    },
    confirmPassword: {
      required: "This field is required.",
      validate: {
        notEqualPassword: (value) =>
          value === getValues("password") ||
          "Password and confirm password must be the same",
      },
    },
  };

  const handleResetPassword = async () => {
    clearError();
    try {
      await sendRequest(
        api.resetPassword({
          password: getValues("password"),
          token,
        })
      );
      setResetPasswordSuccess(true);
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      {pageLoading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : resetPasswordSuccess || error?.message ? (
        <div className={classes.container + " " + classes.boxBackground}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                  <div className={classes.title}>
                    {resetPasswordSuccess
                      ? "Your password has been changed successfully"
                      : error?.message}
                  </div>
                </GridItem>
              </GridContainer>

              {resetPasswordSuccess ? (
                <CheckCircleOutlinedIcon
                  className={classes.checkCircleOutlinedIcon}
                />
              ) : (
                <CancelOutlinedIcon className={classes.cancelOutlinedIcon} />
              )}
            </GridItem>
          </GridContainer>
        </div>
      ) : (
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleResetPassword)}
        >
          <h3>Reset your password</h3>

          <p>Please enter your new password</p>

          <CustomInput
            iconStart={<LockIcon />}
            style={{ margin: 0, paddingTop: 0 }}
            error={errors.password?.message || paramError("password")}
            id="password"
            inputRef={register(validationRules.password)}
            inputProps={{
              onChange: (event) => {
                setValue("password", event.target.value);
              },
              placeholder: "Password",
              name: "password",
            }}
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            inputContainerClasses={classes.inputContainer}
            filled
            passwordIcon
          />

          <CustomInput
            iconStart={<LockIcon />}
            style={{ margin: 0, paddingTop: 0 }}
            error={errors.confirmPassword?.message}
            id="password"
            inputRef={register(validationRules.confirmPassword)}
            inputProps={{
              onChange: (event) => {
                setValue("confirmPassword", event.target.value);
              },
              placeholder: "Confirm Password",
              name: "confirmPassword",
            }}
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            inputContainerClasses={classes.inputContainer}
            filled
            passwordIcon
          />

          <Button
            type="submit"
            className={classes.resetPasswordButton}
            loading={isLoading}
          >
            Reset Password
          </Button>
        </form>
      )}
    </div>
  );
}
