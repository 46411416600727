import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import BookingCard from "../BookingCard/BookingCard";
import BookingCardSkeleton from "../BookingCard/BookingCardSkeleton";
import style from "./activeBookingsStyle.js";
import { useState } from "react";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api";
// import BookingCard from "components/BookingCard/BookingCard";

const useStyles = makeStyles(style);

export default function ActiveBookings() {
  const [loading, setLoading] = React.useState(false);
  const [sendRequest] = useHttpClient();
  const [state, setState] = useState({
    bookings: null,
  });
  const stateHandler = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const classes = useStyles();

  React.useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        const bookings = await sendRequest(api.getActiveBookings());
        // console.log("bookings", bookings);
        stateHandler("bookings", bookings);
        setLoading(false);
      } catch (err) {
        // setLoading(false);
      }
    })();
  }, []);

  let currentPageContentElements = [];
  if (state.bookings && state.bookings.length > 0) {
    state.bookings.forEach((booking, index) => {
      currentPageContentElements.push(
        <BookingCard key={index} booking={booking} />
      );
    });
  } else {
    currentPageContentElements.push(
      <div className={classes.noData}>You have no active bookings. </div>
    );
  }

  return (
    <div>
      {loading ? (
        <BookingCardSkeleton />
      ) : (
        <div>{currentPageContentElements}</div>
      )}
    </div>
  );
}
