import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import PhoneIcon from "@material-ui/icons/Phone";
import authModalHeaderImage from "assets/images/auth-modal-header.png";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomModal from "components/CustomModals/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { useForm } from "react-hook-form";
import api from "services/api";
import { AuthContext } from "shared/context/auth-context";
import { useHttpClient } from "shared/hooks/http-hook";
import validator from "validator";
import styles from "./updatePhoneModalStyle";

const useStyles = makeStyles(styles);

export default function UpdatePhoneModal(props) {
  const { open, onClose } = props;

  const classes = useStyles();

  const [sendRequest, isLoading, error, paramError, clearError] =
    useHttpClient();

  const auth = React.useContext(AuthContext);

  const [isUpdated, setIsUpdated] = React.useState(false);
  const [updatedData, setUpdatedData] = React.useState(null);

  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    defaultValues: {
      phone: "",
      fullname: auth?.userData?.fullname,
    },
  });

  const validationRules = {
    phone: {
      required: "This field is required.",
      validate: {
        phone: (value) =>
          validator.isMobilePhone(
            `+20${value.substring(value.indexOf("1"))}`
          ) || "Phone number not valid",
      },
    },
  };

  const handleAuthentication = async () => {
    clearError();
    try {
      let info = {
        name: getValues("fullname"),
        phone: getValues("phone"),
      };
      info.phone = `+20${info.phone.substring(info.phone.indexOf("1"))}`;

      const res = await sendRequest(api.updateUserInfo(info));
      setUpdatedData(res);

      setIsUpdated(true);
    } catch (err) {}
  };

  const onSuccessClose = () => {
    auth.updateUserData(auth.userData, updatedData);
    onClose();
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      closeButtonClass={classes.closeButton}
      closeButtonIconClass={classes.closeButtonIcon}
      modalBodyClass={classes.modalBodyClass}
    >
      <GridContainer justify="center" className={classes.authModalContainer}>
        <GridItem
          className={classes.noPadding + " " + classes.authModalBody}
          xs={12}
          sm={12}
          md={12}
        >
          <img
            src={authModalHeaderImage}
            className={classes.authModalHeaderImage}
          />
          <h5 className={classes.headerTitle}>Update Personal Info</h5>

          <div className={classes.authModalBodyContainer}>
            <form
              className={classes.form}
              onSubmit={handleSubmit(handleAuthentication)}
            >
              {!isUpdated ? (
                <GridContainer
                  justify="center"
                  className={classes.rootContainer}
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.rootGridItem}
                  >
                    <GridContainer className={classes.headerRootContainer}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.zeroPadding}
                      >
                        <p className={classes.authFormTitle}>
                          Please add your phone number to be able to make a
                          booking.
                        </p>
                      </GridItem>
                    </GridContainer>
                    {error && !error.param && (
                      <FormHelperText className={classes.helperText} filled>
                        {error.message}
                      </FormHelperText>
                    )}

                    <CustomInput
                      iconStart={<PhoneIcon />}
                      style={{ margin: 0, paddingTop: 0 }}
                      error={errors.phone?.message || paramError("message")}
                      id="phone"
                      key="phone"
                      inputRef={register(validationRules.phone)}
                      inputProps={{
                        onChange: (event) => {
                          setValue("phone", event.target.value);
                        },
                        placeholder: "Phone",
                        name: "phone",
                      }}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                      }}
                      inputContainerClasses={classes.inputContainer}
                      filled
                    />

                    <Button
                      type="submit"
                      className={classes.signUpButton}
                      loading={isLoading}
                    >
                      Update Phone
                    </Button>
                  </GridItem>
                </GridContainer>
              ) : (
                <GridContainer
                  justify="center"
                  className={classes.rootContainer}
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.rootGridItem}
                  >
                    <p className={classes.successMessage}>
                      Thank you for updating your phone number. You can now
                      proceed to make your first booking.
                    </p>

                    <Button
                      className={classes.signUpButton}
                      onClick={onSuccessClose}
                    >
                      Close
                    </Button>
                  </GridItem>
                </GridContainer>
              )}
            </form>
          </div>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}
