import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import ActiveBookings from "./ActiveBookings/ActiveBookings.js";
import BookingsHistory from "./BookingsHistory/BookingsHistory.js";
import style from "./myBookingsStyle.js";

const useStyles = makeStyles(style);

export default function MyBookings() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container + " " + classes.gridcontainer}>
        <div>
          <h3 className={classes.myBookingsText}>My Bookings</h3>
          <GridContainer>
            <h3 className={classes.subHeadingText}>Active Bookings</h3>
            <GridItem xs={12} sm={12} md={12}>
              <ActiveBookings />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <h3 className={classes.subHeadingText}>Bookings History</h3>
            <GridItem xs={12} sm={12} md={12}>
              <BookingsHistory></BookingsHistory>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
