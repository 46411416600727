import {
  blackColor,
  container,
  hexToRgb,
  primaryColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

const resetPasswordStyle = (theme) => ({
  form: {
    padding: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 570,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  root: {
    display: "flex",
    justifyContent: "center",
    margin: "175px 0px",
    padding: "0px 0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px",
    },
  },
  container: {
    ...container,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      marginTop: 70,
    },
  },
  title: {
    fontSize: 14,
    marginTop: 15,
    color: blackColor,
    textAlign: "center",
  },
  boxBackground: {
    width: 665,
    height: "42%",
    marginTop: 20,
    marginBottom: 60,
    marginRight: "auto",
    marginLeft: "auto",
    padding: "70px 78px",
    objectFit: "contain",
    borderRadius: "32.5px",
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    backgroundColor: "var(--very-light-pink)",
    backgroundSize: "279px 279px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      padding: "80px 20px",
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
    },
  },
  resetPasswordButton: {
    "& > span:first-child": {
      color: whiteColor,
    },
    padding: 0,
    height: 30,
    width: "100%",
    backgroundColor: primaryColor[0],
    fontFamily: "sans-serif",
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  circularProgress: {
    marginTop: 17,
    color: primaryColor[0],
  },
  cancelOutlinedIcon: {
    display: "block",
    margin: "15px auto",
    width: 100,
    height: 100,
    color: "red",
  },
  formControl: {
    margin: 0,
    paddingTop: 0,
  },
  inputContainer: {
    margin: "10px 0",
  },
  checkCircleOutlinedIcon: {
    width: 100,
    height: 100,
    color: "#05B41C",
  },
});

export default resetPasswordStyle;
