import { textColor, whiteColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const newsModalStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  root: {
    height: "100%",
    borderRadius: "10px",
    paddingTop: 40,
    paddingBottom: 0,
    padding: "20px",
    backgroundColor: whiteColor,
    boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.5)",
    width: "100%",
    "@media (min-width: 576px)": {
      width: "540px",
    },
    "@media (min-width: 768px)": {
      width: "720px",
    },
    "@media (min-width: 960px)": {
      width: "920px",
    },
    // "@media (min-width: 1300px)": {
    //   width: "1200px",
    // },
  },
  imageContainer: {
    float: "right",
    padding: "20px",
    width: "100%",
    "@media (min-width: 768px)": {
      width: "50%",
    },
  },
  image: {
    width: "100%",
    float: "right",
    borderRadius: "10px",
    objectFit: "contain",
  },
  text: {
    color: textColor[0],
    fontWeight: "500",
  },
  modalFooter: {
    padding: "15px",
    marginTop: "auto",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    // alignSelf: "flex-end",
    textAlign: "center",
    display: "block",
    width: "100%",
    padding: "10px 20px",
    maxWidth: "200px",
    "& > span:first-child": {
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  },
  date: {
    fontWeight: "500",
    fontSize: "0.8rem",
    display: "inline-block",
    color: "gray",
  },
  title: {
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "1.0rem",
    marginBottom: "30px",
    marginRight: "10px",
    display: "inline-block",
  },
});

export default newsModalStyle;
