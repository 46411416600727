
import {
  container,
  mlAuto,
  mrAuto,
  pageRoot,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const bookingsHistoryStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    ...pageRoot,
    padding: "50px 0px",
    backgroundSize: "100%",
    backgroundPosition: "top center;",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "180%",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "100%",
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },

  gridcontainer: {
    
   
  },
  tableContainer: {
    borderRadius: "10px",
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.5)",
    backgroundColor: whiteColor,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
    },
  },

  

  
  
});

export default bookingsHistoryStyle;
