import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Skeleton from "@material-ui/lab/Skeleton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import style from "./locationDetailsStyle.js";

const useStyles = makeStyles(style);

export default function LocationDetailsSkeleton() {
  const classes = useStyles();

  return (
    <div>
      {/* <div className={classes.topBanner} style={{ backgroundImage: "none" }}> */}
      <Skeleton
        style={{
          maxWidth: "100%",
          height: 200,
          transform: "none",
          transformOrigin: "none",
          backgroundImage: "none",
        }}
        className={classes.topBanner}
      ></Skeleton>
      {/* </div> */}
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={6} sm={12} xs={12}>
            <GridContainer justify="center">
              <GridItem>
                <Skeleton>
                  <h3 className={classes.title}>lorem ipsum</h3>
                </Skeleton>
                <Skeleton style={{ maxWidth: "100%" }}>
                  <div className={classes.whiteContainer}></div>
                </Skeleton>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem>
                <Skeleton style={{ maxWidth: "100%" }}>
                  <div className={classes.whiteContainer}></div>
                </Skeleton>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem md={6} sm={12} xs={12}>
            <Skeleton>
              <h3 className={classes.title}>lorem ipsum</h3>
            </Skeleton>
            <Skeleton style={{ maxWidth: "100%" }}>
              <div className={classes.whiteContainer}></div>
            </Skeleton>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem md={12} sm={12} xs={12}>
            <Skeleton style={{ maxWidth: "100%" }}>
              <div
                className={classes.whiteContainer + " " + classes.mapsContainer}
              ></div>
            </Skeleton>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
