import { makeStyles } from "@material-ui/core/styles";
import Card from "components/BookingCard/BookingCard";
import ConfirmModal from "components/CustomModals/ConfirmModal/ConfirmModal.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import strings from "constants/strings";
import React from "react";
import style from "./bookingCardStyle.js";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api";
import { useHistory } from "react-router";

const useStyles = makeStyles(style);

export default function BookingCard(props) {
  const { booking } = props;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [activeScreen, setActiveScreen] = React.useState("question");
  const history = useHistory();

  const [sendRequest, loading] = useHttpClient();

  const bookingDate = new Date(booking.from)
    .toLocaleString("en-GB", { timeZone: "Africa/Cairo" })
    .split(",")[0];
  const bookingTime = new Date(booking.from).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Africa/Cairo",
  });

  const classes = useStyles();

  const cancelBooking = async () => {
    try {
      const response = await sendRequest(api.cancelBooking(booking.id));
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  return (
    <div className={classes.root}>
      <ConfirmModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setTimeout(() => {
            setActiveScreen("question");
            window.scrollTo({ top: 0 });
            history.push("/mybookings");
          }, 300);
        }}
        headerMessage="Cancel Booking"
        confirmMessage="Are you sure you want to cancel this booking?"
        errorMessage="Couldn't cancel your booking. Please contact the support to cancel it."
        successMessage="Booking cancelled successfully."
        onConfirm={cancelBooking}
        loading={loading}
        activeScreen={activeScreen}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}>
          <h3 className={classes.subHeadingText}>Starting At:</h3>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer className={classes.dateHeadingContainer}>
            <GridItem xs={3} sm={3} md={2} className={classes.dateMonthHeading}>
              <h3 className={classes.dateHeading}>Hour</h3>
              <h3 className={classes.dateText}>{bookingTime}</h3>
            </GridItem>
            <GridItem xs={3} sm={3} md={2}>
              <h3 className={classes.dateHeading}>Day</h3>
              <h3 className={classes.dateText}>{bookingDate.split("/")[0]}</h3>
            </GridItem>
            <GridItem xs={3} sm={3} md={2} className={classes.dateMonthHeading}>
              <h3 className={classes.dateHeading}>Month</h3>
              <h3 className={classes.dateText}>
                {strings.months[bookingDate.split("/")[1] - 1]}
              </h3>
            </GridItem>
            <GridItem xs={3} sm={3} md={2}>
              <h3 className={classes.dateHeading}>Year</h3>
              <h3 className={classes.dateText}>{bookingDate.split("/")[2]}</h3>
            </GridItem>
          </GridContainer>
          <Card
            booking={booking}
            withCancel
            cancelHandler={() => {
              setModalOpen(true);
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
