import { blackColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const customCarouselStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    marginBottom: 50,
    "& .slick-list": {
      [theme.breakpoints.down("sm")]: {
        margin: "0px -27px",
      },
    },
    "& .slick-prev::before": {
      color: blackColor,
      fontSize: "22px !important",
    },
    "& .slick-next::before": {
      color: blackColor,
      fontSize: "22px !important",
    },
    "& .slick-prev": {
      left: -100,
      transform: "translateX(-50%) !important",
      height: "fit-content !important",
      bottom: "-12% !important",
      left: "calc(50% - 40px) !important",
      top: "unset !important",
      width: "60px !important",
    },
    "& .slick-next": {
      right: -100,
      // [theme.breakpoints.down("sm")]: {
      transform: "translateX(50%) !important",
      height: "fit-content !important",
      bottom: "-12% !important",
      right: "calc(50% - 40px) !important",
      width: "60px !important",
      top: "unset !important",
      // },
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
});

export default customCarouselStyle;
