import Footer from "components/Footer/Footer";
import Header from "components/Header/Header.js";
import { createBrowserHistory } from "history";
import React from "react";
import { Route, Router, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { AuthContext } from "shared/context/auth-context";
import { useAuth } from "shared/hooks/auth-hook";
import AccountSettings from "views/AccountSettings/AccountSettings";
import ContactUs from "views/ContactUs/ContactUs";
import EmailConfirmation from "views/EmailConfirmation/EmailConfirmation";
import EmailVerification from "views/EmailVerification/EmailVerification";
import Home from "views/Home/Home";
import HowToPlay from "views/HowToPlay/HowToPlay";
import MyBookings from "views/MyBookings/MyBookings";
import LocationDetails from "views/LocationDetails/LocationDetails";
import Locations from "views/Locations/Locations";
import News from "views/News/News";
import ResetPassword from "views/ResetPassword/ResetPassword";
import "./assets/scss/material-kit-pro-react.scss?v=1.9.0";
import BookingSuccess from "views/BookingSuccess/BookingSuccess";
import Loading from "components/Loading/Loading";
import { pageRoot } from "assets/jss/material-kit-pro-react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./appStyle";

var hist = createBrowserHistory();

const useStyles = makeStyles(styles);

const App = () => {
  const { userData, login, logout, updateUserData } = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);

  const classes = useStyles();

  React.useEffect(() => {
    if (userData !== false) {
      setIsLoading(false);
    }
  }, [userData]);

  let routes;

  const commonRoutes = [
    <Route
      exact
      path="/locations"
      render={(props) => <Locations {...props} key={Math.random()} />}
      key="locations-route"
    />,
    <Route
      exact
      path="/"
      render={(props) => <Home {...props} key={Math.random()} />}
      key="home-route"
    />,
    <Route
      exact
      path="/contactus"
      render={(props) => <ContactUs {...props} key={Math.random()} />}
      key="contactus-route"
    />,
    <Route
      exact
      path="/news"
      render={(props) => <News {...props} key={Math.random()} />}
      key="news-route"
    />,
    <Route
      exact
      path="/howtoplay"
      render={(props) => <HowToPlay {...props} key={Math.random()} />}
      key="howtoplay-route"
    />,
    <Route
      exact
      path="/location"
      render={(props) => <LocationDetails {...props} key={Math.random()} />}
      key="location-route"
    />,
  ];

  if (!!userData && (userData.email ? userData.emailVerified : true)) {
    routes = (
      <Switch>
        {commonRoutes}
        <Route
          exact
          path="/profile"
          component={AccountSettings}
          key="profile-route"
        />
        <Route
          exact
          path="/mybookings"
          render={(props) => <MyBookings {...props} key={Math.random()} />}
          key="mybookings-route"
        />
        <Route
          exact
          path="/bookingsuccess"
          render={(props) => <BookingSuccess {...props} key={Math.random()} />}
          key="bookingsuccess-route"
        />
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        {commonRoutes}
        <Route
          path="/auth/email/confirm"
          render={(props) => <EmailConfirmation {...props} key={Math.random()} />}
          key="email-confirmation-route"
        />
        <Route
          path="/auth/email/verify"
          component={EmailVerification}
          key="email-verification-route"
        />
        <Route
          path="/auth/resetpassword"
          component={ResetPassword}
          key="password-reset-route"
        />
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn:
          !!userData && (userData.email ? userData.emailVerified : true),
        userData: userData,
        login: login,
        logout: logout,
        updateUserData: updateUserData,
      }}
    >
      <Router history={hist}>
        <Header
          absolute
          color="transparent"
          changeColorOnScroll={{
            color: "white",
          }}
        />
        <main>
          <Loading
            loading={isLoading}
            style={{ paddingTop: 113, height: "90vh" }}
            rootClass={classes.loading}
          >
            <Switch>{routes}</Switch>
          </Loading>
        </main>
        <Footer />
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
