
import {
  container,
  mlAuto,
  mrAuto,
  pageRoot,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const activeBookingsStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    ...pageRoot,
    padding: "50px 0px",
    backgroundSize: "100%",
    backgroundPosition: "top center;",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "180%",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "100%",
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  noData: {
    textAlign: "left",
    marginLeft: 20,
    marginTop: 10,
    color: grayColor[10],
  },
 

  
  
});

export default activeBookingsStyle;
