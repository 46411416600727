import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SelectInput from "components/SelectInput/SelectInput";
import React from "react";
import Datetime from "react-datetime";
import { useGoogleMaps } from "react-hook-google-maps";
import { useLocation } from "react-router-dom";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api.js";
import { getWeekDays, hoursFormatToInt } from "utils/index.js";
import Calendar from "./Calendar/Calendar.js";
import style from "./locationDetailsStyle.js";
import LocationDetailsSkeleton from "./LocationDetailsSkeleton.js";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router";
import { AuthContext } from "shared/context/auth-context";
import locationBanner from "../../assets/images/location-banner.png";
import UpdatePhoneModal from "components/CustomModals/UpdatePhoneModal/UpdatePhoneModal.js";
import { addTimeOffset } from "utils/index.js";
import { cairoTimeOffset } from "utils/index.js";

const useStyles = makeStyles(style);

export default function LocationDetails() {
  const classes = useStyles();
  let locationHook = useLocation();
  let history = useHistory();
  // const { userData } = useAuth();
  const auth = React.useContext(AuthContext);

  const [fetchLocationData, pageLoading] = useHttpClient();
  const [fetchCourtData] = useHttpClient();
  const [bookCourtRequest, bookLoading, bookingError, _, clearError] =
    useHttpClient();
  const [fetchUserPenalty] = useHttpClient();

  const [location, setLocation] = React.useState(null);
  const [pageError, setPageError] = React.useState(false);
  const [selectedCourt, setSelectedCourt] = React.useState(0);
  const [court, setCourt] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(0);
  const [selectedDuration, setSelectedDuration] = React.useState(0);
  const [mapUrl, setMapUrl] = React.useState("https://maps.google.com/");
  const [mapsClasses, setMapsClasses] = React.useState(classes.maps);
  const [userPenalty, setUserPenalty] = React.useState(0);
  const [showPhoneModal, setShowPhoneModal] = React.useState(false);

  const { ref, map, google } = useGoogleMaps(
    `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    {
      center: { lat: 30.0444, lng: 31.2357 },
      zoom: 15,
    }
  );

  React.useEffect(() => {
    if (auth && auth.userData && !auth?.userData?.phone) {
      setShowPhoneModal(true);
    }
  }, [auth]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const locationId = new URLSearchParams(locationHook.search).get("q");
    if (locationId) {
      (async () => {
        try {
          const response = await fetchLocationData(
            api.getLocationById(locationId)
          );
          setLocation({
            ...response,
            courts: response.courts.map((court) => ({
              ...court,
              value: court.id,
            })),
          });
          const date = new Date(response.currentTimestamp);
          date.setUTCHours(0, 0, 0, 0);

          setSelectedDate(date.toISOString());
          if (response.courts.length > 0) {
            setSelectedCourt(response.courts[0].id);
            const courtData = await fetchCourtData(
              api.getCourtById(response.courts[0].id)
            );
            setCourt(courtData);
          }
        } catch (err) {
          setPageError(true);
        }
      })();
    } else {
      setPageError(true);
    }
  }, []);

  React.useEffect(() => {
    if (map && location) {
      new google.maps.Marker({
        position: {
          lat: parseFloat(location.latLong.split(", ")[0]),
          lng: parseFloat(location.latLong.split(", ")[1]),
        },
        map,
      });
      setMapUrl(
        `https://www.google.com/maps?q=${parseFloat(
          location.latLong?.split(", ")[0]
        )},${parseFloat(location.latLong?.split(", ")[1])}`
      );
      map.setCenter({
        lat: parseFloat(location.latLong.split(", ")[0]),
        lng: parseFloat(location.latLong.split(", ")[1]),
      });
    }
    setTimeout(() => {
      setMapsClasses(classes.maps + " " + classes.mapsFix);
    }, 3000);
  }, [map, location]);

  React.useEffect(() => {
    clearError();
  }, [selectedCourt, selectedDuration, selectedTime, selectedDate]);

  React.useEffect(() => {
    if (auth.isLoggedIn)
      (async () => {
        try {
          const response = await fetchUserPenalty(api.getUserPenalty());
          setUserPenalty(response.penalty);
        } catch {}
      })();
  }, [auth]);

  const handleSelectCourt = async (event) => {
    setSelectedCourt(event.target.value);
    try {
      const response = await fetchCourtData(
        api.getCourtById(event.target.value)
      );
      setCourt(response);
    } catch (err) {}
  };

  const handleSelectDate = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSelectTime = (event) => {
    setSelectedTime(`${event._d.getHours()}:${event._d.getMinutes()}`);
  };

  const handleSelectDuration = (event) => {
    setSelectedDuration(event.target.value);
  };

  const handleBook = async () => {
    if (court && selectedDuration && selectedTime && selectedDate) {
      try {
        let from = new Date(selectedDate);
        from.setHours(selectedTime.split(":")[0]);
        from.setMinutes(selectedTime.split(":")[1]);
        const bookingData = {
          date: from.toISOString(),
          duration: parseInt(selectedDuration),
        };
        const response = await bookCourtRequest(
          api.bookCourt(court.id, bookingData)
        );
        history.push({
          pathname: "/bookingsuccess",
          state: { response },
        });
      } catch (err) {
        if (err?.response?.data?.status === "MISSING_PHONE_NUMBER") {
          setShowPhoneModal(true);
        }
        try {
          const response = await fetchCourtData(api.getCourtById(court.id));
          setCourt(response);
        } catch (err) {}
      }
    }
  };

  let price = 0.0;

  if (selectedDate !== 0 && selectedTime !== null && selectedDuration > 0) {
    if (court) {
      price = 0.0;
      for (let i = 0; i < selectedDuration / 30; i++) {
        let currentSlot = hoursFormatToInt(selectedTime) + i;
        let specialPricingFound = false;
        court.variablePricing[selectedDate].forEach((pricingEvent) => {
          let from = hoursFormatToInt(pricingEvent.from) + cairoTimeOffset * 2; //convert to cairo time (+2 hours -> +4 slots)
          let to = hoursFormatToInt(pricingEvent.to) + cairoTimeOffset * 2;
          if (currentSlot >= from && currentSlot < to) {
            price += pricingEvent.price / 2;
            specialPricingFound = true;
          }
        });
        if (!specialPricingFound) {
          price += court.price / 2;
        }
      }
    }
  }

  return (
    <div className={classes.root}>
      <UpdatePhoneModal
        open={showPhoneModal}
        onClose={() => {
          setShowPhoneModal(false);
        }}
      />
      {pageLoading || pageError ? (
        <div>
          <LocationDetailsSkeleton />
        </div>
      ) : (
        <div>
          <div
            style={{
              backgroundImage: `linear-gradient(
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.4)
              ), url('${locationBanner}')`,
              backgroundSize: "cover",
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
              minHeight: 200,
              paddingTop: 6,
              paddingBottom: 20,
              marginBottom: 40,
            }}
            className={classes.topBanner}
          >
            <div className={classes.container}>
              <h1 className={classes.locationHeading}>{location?.name}</h1>
              <div className={classes.subtitleContainer}>
                <LocationOnIcon className={classes.subtitleIcon} />
                <span className={classes.subtitle}>{location?.address}</span>
              </div>
              <div className={classes.subtitleContainer}>
                <PhoneIcon className={classes.subtitleIcon} />
                <span className={classes.subtitle}>{location?.phone}</span>
              </div>
              <div className={classes.subtitleContainer}>
                <EmailIcon className={classes.subtitleIcon} />
                <span className={classes.subtitle}>{location?.email}</span>
              </div>
            </div>
          </div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem md={6} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem>
                    <h3 className={classes.title}>
                      Please select the date, time and duration according to the
                      court schedule
                    </h3>
                    <div className={classes.whiteContainer}>
                      <h3
                        className={classes.title + " " + classes.containerTitle}
                      >
                        Book within one week
                      </h3>
                      <div className={classes.bookingForm}>
                        <div className={classes.selectContainer}>
                          <div className={classes.inputLabel}>
                            <div className={classes.labelText}>Court</div>
                            <span className={classes.separator} />
                          </div>
                          <SelectInput
                            id="courts-select"
                            selectStyle={classes.select}
                            placeholder={"Select court"}
                            data={location?.courts}
                            value={selectedCourt}
                            onSelect={handleSelectCourt}
                          />
                        </div>
                        <div className={classes.selectContainer}>
                          <div className={classes.inputLabel}>
                            <div className={classes.labelText}>Date</div>
                            <span className={classes.separator} />
                          </div>
                          <SelectInput
                            id="date-select"
                            selectStyle={classes.select}
                            placeholder={"Select date"}
                            data={getWeekDays(
                              location
                                ? new Date(location.currentTimestamp)
                                : new Date()
                            )}
                            value={selectedDate}
                            onSelect={handleSelectDate}
                          />
                        </div>
                        <div className={classes.selectContainer}>
                          <div className={classes.inputLabel}>
                            <div className={classes.labelText}>Time</div>
                            <span className={classes.separator} />
                          </div>
                          <div className={classes.timpicker}>
                            <Datetime
                              dateFormat={false}
                              timeFormat={"h A"}
                              inputProps={{
                                placeholder: "Select Time",
                                readOnly: true,
                              }}
                              timeConstraints={{
                                minutes: { step: 60 },
                              }}
                              onChange={handleSelectTime}
                            />
                          </div>
                        </div>
                        <div className={classes.selectContainer}>
                          <div className={classes.inputLabel}>
                            <div className={classes.labelText}>Duration</div>
                            <span className={classes.separator} />
                          </div>
                          <SelectInput
                            id="duration-select"
                            selectStyle={classes.select}
                            placeholder={"Select duration"}
                            data={[
                              // { name: "30 minutes", value: 30 },
                              { name: "1 hour", value: 60 },
                              // { name: "1 hour 30 minutes", value: 90 },
                              { name: "2 hours", value: 120 },
                              // { name: "3 hours", value: 180 },
                            ]}
                            value={selectedDuration}
                            onSelect={handleSelectDuration}
                          />
                        </div>
                        <div className={classes.priceContainer}>
                          <div className={classes.inputLabel}>
                            <div className={classes.labelText}>Price</div>
                            <span className={classes.separator} />
                          </div>
                          <div className={classes.price}>
                            {price} LE{" "}
                            {userPenalty > 0 ? (
                              <div className={classes.penalty}>
                                (+ {parseInt(userPenalty)} LE penalty)
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <Fade collapse when={Boolean(bookingError)}>
                          <div className={classes.error}>
                            {bookingError?.message === "Unauthorized"
                              ? "Please log in first."
                              : bookingError?.message}
                          </div>
                        </Fade>
                        <Button
                          onClick={handleBook}
                          loading={bookLoading}
                          color="primary"
                          className={classes.button}
                          round
                          disabled={
                            court &&
                            selectedDuration &&
                            selectedTime &&
                            selectedDate
                              ? false
                              : true
                          }
                        >
                          Book Now
                        </Button>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem>
                    <div
                      className={
                        classes.whiteContainer +
                        " " +
                        classes.descriptionContainerLg
                      }
                    >
                      <h3
                        className={classes.title + " " + classes.containerTitle}
                      >
                        Description
                      </h3>
                      <p>{location?.description}</p>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem md={6} sm={12} xs={12}>
                <h3 className={classes.title}>
                  Court Schedule
                  <br className={classes.break} />
                  <br className={classes.break} />
                </h3>
                <div className={classes.whiteContainer}>
                  <Calendar
                    start={
                      location
                        ? addTimeOffset(location.workingHoursFrom)
                        : "09:00"
                    }
                    end={
                      location
                        ? addTimeOffset(location.workingHoursTo)
                        : "24:00"
                    }
                    selectedDate={
                      selectedDate === 0
                        ? new Date(new Date().setHours(0, 0, 0, 0))
                        : new Date(selectedDate)
                    }
                    setSelectedDate={setSelectedDate}
                    events={court?.timetable}
                    courtName={court?.name}
                    now={
                      location
                        ? new Date(
                            new Date(location.currentTimestamp).setUTCHours(
                              0,
                              0,
                              0,
                              0
                            )
                          )
                        : new Date(new Date().setHours(0, 0, 0, 0))
                    }
                  />
                </div>
                {/* <div
              className={
                classes.whiteContainer + " " + classes.openingHoursContainer
              }
            >
              <h3 className={classes.title + " " + classes.containerTitle}>
                Opening Hours
              </h3>
            </div> */}
                <div
                  className={
                    classes.whiteContainer +
                    " " +
                    classes.descriptionContainerMd
                  }
                >
                  <h3 className={classes.title + " " + classes.containerTitle}>
                    Description
                  </h3>
                  <p>{location?.description}</p>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={12} sm={12} xs={12}>
            <div
              className={
                pageLoading || pageError
                  ? classes.displayNone
                  : classes.whiteContainer + " " + classes.mapsContainer
              }
            >
              <div
                key={"maps-div"}
                // style={{ display: "none" }}
                ref={ref}
                className={mapsClasses}
              />
              {/* <div className={classes.maps} style={{ overflow: "hidden" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=30.046100747429524,31.201717680437717&scale=2
                      &zoom=15&size=600x200&maptype=roadmap
                      &markers=color:red%7C30.046100747429524,31.201717680437717
                      &key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                  />
                </div>
              </div> */}
              <div className={classes.findUsRoot}>
                <div className={classes.findUsContainer}>
                  <h3 className={classes.title + " " + classes.containerTitle}>
                    Find us now
                  </h3>
                  <div className={classes.subtitleContainer}>
                    <LocationOnIcon
                      className={classes.subtitleIcon + " " + classes.textColor}
                    />
                    <p>{location?.address}</p>
                  </div>
                  <div className={classes.subtitleContainer}>
                    <PhoneIcon
                      className={classes.subtitleIcon + " " + classes.textColor}
                    />
                    <p>{location?.phone}</p>
                  </div>
                  <a
                    href={mapUrl}
                    target={"_blank"}
                    className={classes.getDirections}
                  >
                    Get Directions
                  </a>
                </div>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
